@import 'src/style/variable/variables.module';
.settings-panel {
  width: 400px;
  height: calc(100% + 48px);
  color: $primary-flat;
  padding-left: 16px;
}

.settings-panel > .loading {
  align-self: center;
  text-align: center;
  margin-top: 60px;
}

.settings-panel .MuiTreeItem-content {
  height: 40px;
  padding-left: 24px;
}

.settings-panel .MuiTreeItem-content {
  padding: 0;
}

.settings-panel>ul {
  margin-left: -16px;
  overflow-y: auto;
}

.settings-panel ul>li ul {
  margin-left: 0px;
}

.settings-panel .tree-item-custom-label {
  line-height: 40px;
  display: flex;
  align-items: center;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: space-between;
}

.settings-panel .tree-item-custom-label > div {
  width: 40px;
}

.settings-panel .tree-item-custom-label  > span {
  width: 300px;
  height: 35px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.settings-panel .tree-item-custom-label>svg {
  margin: 0 0 0 auto;
  display: block;
}

.settings-panel .device-menu-search {
  display: flex;
  padding: 5px;
  display: flex;
  align-items: center;
}

.settings-panel .menuList {
  width: 200px;
}

.settings-panel .title-and-icon {
  display: flex;
}

.settings-panel .title-and-icon p {
  margin: 0;
}

.settings-panel .configure-search-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $grey-light;
  margin-left: 20px;
}

.settings-panel .configure-search-row {
  cursor: pointer;
}

.settings-panel .configure-search-row {
  font-size: 18px;
  border-radius: 10px;
  transition: box-shadow 0.2s ease-in-out, transform 0.1s ease-in-out; /* Smooth transition for multiple effects */
}

.settings-panel .configure-search-row:hover {
  /* Enhanced box-shadow with higher blur and opacity */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.25);

  /* Optional: Slight background color change on hover */
  background-color: rgba(255, 255, 255, 0.1); /* Adjust to your needs */
}

.settings-panel .settings-panel .configure-search-row:hover .add-setting-icon {
  font-size: 24px;
}

.settings-panel .MuiTreeItem-label {
  font-size: 14px;
}

@media (max-width: $mobile-width) {
  .settings-panel {
    width: auto;
  }
  .settings-panel h3{
    color: $primary-solid;
  }
  .settings-panel .device-menu-search {
    justify-content: right;
  }
}
