@import 'src/style/variable/variables.module';

.pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 20px 0 20px 84px;
}

.pagination > div {
  font-size: 13px;
  color: $grey;
  padding: 0 10px;
  display: flex;
  height: 32px;
  margin-bottom: auto;
}

.pagination .MuiInputBase-root {
  padding: 0 10px;
}

.pagination .MuiSelect-select.MuiSelect-select {
  padding-right: 6px;
}

.pagination .button-icon, .pagination .button-icon svg {
  width: 24px;
  height: 24px;
}

.pagination .button {
  padding: 0 6px;
  margin-top: -4px;
}

.pagination .logo-spinner {
  position: absolute;
  left: 10px;
}

@media (max-width: $mobile-width) {
  .pagination {
    padding: 10px 0px;
  }
  .pagination > div {
    padding: 0px;
  }
}
