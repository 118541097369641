@import 'src/style/variable/variables.module';
.alarms-list h4 {
  margin: 15px 0 10px;
}

.alarms-list {
  position: relative;
  max-width: calc(90vw);
}
.alarms-list h3 {
  margin: 10px 0px;
}

.acknowledge-indication {
  text-align: center;
  border: 1px solid $quaternary;
  background-color: $quaternary;
  border-radius: 4px;
  width: 40px;
  font-weight: bold;
}
.not-acknowledge-indication {
  cursor: pointer;
  text-align: center;
  border: 1px solid $tertiary;
  background-color: $tertiary;
  border-radius: 4px;
  width: 40px;
  font-weight: bold;
}

@media (max-width: $mobile-width) {
  .alarms-list .table-filter {
    float: right;
  }
}

.alarms-list .critical .alert-icon {
  color: $error;
}
