@import 'src/style/variable/variables.module';

#maintenance .rdt_TableCell > div {
  width: 100%;
}

#maintenance .rdt_TableCell {
  text-align: left;
  vertical-align: top;
  display: table-cell;
  padding-top: 8px;
}

.alarm-list {
  height: 24px;
  margin-top: 3px;
  padding-top: 2px;
  text-align: left;
  vertical-align: middle;
}

.maintenance-actions button {
  float: right;
  padding: 0 4px 0 4px;
}

#maintenance .MuiPaper-root {
  margin-bottom: 24px;
}

#maintenance .rdt_TableHeader {
  min-height: inherit;
}

#maintenance .line-through {
  text-decoration: line-through;
  color: $grey;
}

#maintenance h3 {
  margin: 10px 0 20px;
}

#maintenance h2 {
  text-align: center;
  width: 100%;
}

#maintenance .expandable {
  cursor: pointer;
}

#maintenance .expand-arrow {
  font-size: 10pt;
}

#maintenance .expand-arrow {
  font-size: 10pt;
}

#maintenance .error-text .label {
  padding: 0 0 0 5px;
  display: inline-block;
}
#maintenance .error-text .icon {
  position: relative;
  top: 2px;
  display: inline-block;
}

#maintenance .cell-with-icon .icon {
  display: inline-block;
  padding-right: 8px;
  padding-top: 5px;
}

#maintenance .cell-with-icon .label {
  display: inline-block;
  padding-top: 5px;
  vertical-align: top;
}

#maintenance .cell-with-icon {
  display: inline-block;
  margin-top: -5px;
}

#maintenance .maintenance-title-bar{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

#maintenance .maintenance-title-bar h2{
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

#maintenance .group-dismiss-button {
  float: right;
  margin-bottom: 15px;
}

.toast a.finishReport:hover {
  color: $grey;
}
