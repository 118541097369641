@import 'src/style/variable/variables.module';
#client .rdt_TableHeader {
  min-height: inherit;
}

.MuiFormLabel-root .input-label {
  display: inline;
}
.MuiFormLabel-root.mfa-label {
  font-weight: 600;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.6);
  padding-top: 20px;
}
.mfa-switch {
  margin-top: 5px;
}

.MuiFormLabel-root .input-tooltip {
  margin-left: 5px;
  font-weight: 600 !important;
  font-size: 14pt !important;
  color: $grey-dark;
}
