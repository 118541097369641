#devices.page .add-panel {
  height: auto;
}
.add-panel .table {
  height: 400px;
}
.panel-info {
  margin-bottom: 10px;
}

.button-row {
  display: flex;
  justify-content: space-around;
  width: 160px;
  margin: 5px 5px 0px auto;
}

.MuiDataGrid-cell,
.MuiDataGrid-columnHeader {
  font-size: 12px;
}

.table .MuiDataGrid-root {
  border: none;
}
