@import 'src/style/variable/variables.module';

.footer-bar {
  height: 54px;
  border-top: 1px solid $grey-light;
  position: sticky;
  background-color: white;
  width: calc(100%);
  margin-left: -64px;
  bottom: 0;
  left: 0;
  right: 0;
}

.footer-bar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.footer-bar-main {
  display: inline-flex;
  padding-left: 20px;
  height: 100%;
  width: 100%;
  align-items: center;
}

.footer-bar-main > span {
  padding-left: 0px;
}

.footer-bar-cta {
  padding-right: 0px;
}

.footer-bar-placeholder {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.footer-bar-container img {
  height: 40px;
}

@media (--print) {
  .footer-bar-container {
    justify-content: left;
  }

  .footer-bar {
    margin-bottom: 20px;
  }
}

@media (--mobile) {
  .footer-bar-container  {
    flex-flow: row wrap;
  }
  .footer-bar {
    height: auto;
  }
}

.footer-bar-button {
  width: 100%;
  display: flex;
  justify-content: right;
}

.footer-bar-button button {
  margin-right: 10px;
}
