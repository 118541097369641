@import 'src/style/variable/variables.module';

.error-message {
  color: $error;
}

.error-message > a {
  display: inline-block;
  font-weight: 500;
  cursor: pointer;
  position: relative;
  user-select: none;
  text-shadow: 2px 2px 0px #fff, -2px 0px 0px #fff, 2px 0px 0px #fff, -2px 2px 0px #fff;

  &:after {
    content: '';
    width: 100%;
    height: 2px;
    background: #ff0000;
    display: block;
    z-index: -1;
    bottom: 5px;
    left: 0;
    position: absolute;
  }
}
