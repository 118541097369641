.login-message {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.login-message > span {
  margin-left: 10px;
}

#LoginPage .logoBox {
  text-align: center;
}

.auth-screen.errors {
  max-width: 45rem;
  text-align: center;
}

.auth-screen.errors ul {
  padding-inline-start: 0;
}

.auth-screen.errors li {
  list-style-type: none;
}

.centered-outer {
  display: table;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.centered-middle {
  height: 100%;
  padding: 0;
  margin: 0;
  display: table-cell;
  vertical-align: middle;
}

.centered-inner {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  min-width: 240px;
  max-width: 400px;
  padding: 0 20px;
}
