@import 'src/style/variable/variables.module';
.app-bar {
  width: 100%;
  height: 54px;
  border-bottom: 1px solid $grey-light;
}

.app-bar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

.app-bar-main {
  display: inline-flex;
  padding-left: 20px;
  height: 100%;
  width: 100%;

  align-items: center;
}

.app-bar-main > span {
  padding-left: 15px;
}

.app-bar-right {
  margin-left: auto;
  padding-right: 20px;
}

.knowledge-base-label {
  vertical-align: middle;
  padding-left: 5px;
}
.knowledge-base-link {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-bar-container img {
  height: 40px;
}

.app-bar-container .knowledge-icon {
  width: 32px;
  height: 32px;
  color: $primary;
  justify-self: center;
}

.app-bar-container a:hover svg,
.app-bar-container a:active svg,
.app-bar-container a:focus svg {
  color: $violet;
}

.app-bar-admin {
  background-color: $primary;
}

.admin-title-text {
  color: white;
}

@media (--print) {
  .app-bar-container {
    justify-content: left;
  }

  .app-bar {
    margin-bottom: 20px;
  }
}

@media (--mobile) {
  .app-bar-container  {
    flex-flow: row wrap;
  }
  .app-bar {
    height: auto;
  }
}
@media (max-width: $mobile-width) {
  .app-bar-main {
    padding-left: 60px;
  }
  .app-bar-container .knowledge-base-label {
    display: none;
  }
}
