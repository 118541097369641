.graph-options {
  display: flex;
  margin-right: 0px;
  margin-left: auto;
  width: 200px;
  justify-content: space-around;
}
.graph-options .MuiSelect-select{
  padding: 10px
}

.sensor-title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.sensor-history-title {
  font-size: 1.17em;
  font-weight: 600;
}
