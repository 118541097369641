@import 'src/style/variable/variables.module';

.offline-banner {
  background-color: $warning;
  padding: 10px;
  text-align: center;
  font-weight: bold;
}
.offline-banner .button {
  padding: 5px;
  margin-left: 30px;
}

.tableWrapper table .MuiTableCell-root{
  word-break: break-all;
  max-width: 500px;
}
