.offline-queue {
  width: 70%
}
@media screen and (max-device-width: 1024px) {
  .offline-queue {
    width: 100%
  }
}

.offline-queue .tableWrapper {
  max-height: 400px;
  overflow-y: scroll;
}

.offline-queue .buttonWrap {
  margin-left: auto;
  margin-right: 0px;
  width: 150px;
}
