@import 'src/style/variable/variables.module';

.upload-status-table .rdt_TableHeader {
  min-height: inherit;
}

.upload-status-table .rdt_TableCell > div {
  width: 100%;
  text-align: left;
}

.upload-status-table .rdt_TableCell > div  .MuiLinearProgress-root {
  height: 10px;
  border-radius: 6px;
  width: 50%;
}

.upload-status-table .rdt_TableCell > div .success .MuiLinearProgress-bar {
  background-color: $info;
}

.upload-status-table .rdt_TableCell > div .error .MuiLinearProgress-bar,
.upload-status-table .rdt_TableCell > div .failed .MuiLinearProgress-bar {
  background-color: $error;
}
.upload-status-table .rdt_TableCell > div .rejected .MuiLinearProgress-bar {
  background-color: $warning;
}
