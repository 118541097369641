@import 'src/style/variable/variables.module';

.recipe-modal{
  width: 100%
}
.modal.recipe-modal{
  width: 1000px;
}

.button-bar {
    display: inline-block; /* or inline-flex */
    text-align: right; /* Align text content to the right */
    width: 100%; /* Ensure the div takes up the full width */
}

.h2 {
    color: red;
}
.BatchDevices .ToggleButton-text {
  font-weight: bold;
  font-size: 10px;
}
.confirm-buttons .buttonGroupDrop .dropDown {
  margin: 0px;
}
.confirm-buttons .MuiOutlinedInput-notchedOutline {
  border: none;
}
.confirm-buttons .MuiSelect-iconOutlined{
  display: none;
}
.confirm-buttons .custom-options {
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.12);
  width: fit-content;
  border-radius: 3px;
  background-color: $primary-flat;
  color: white;
}
.confirm-buttons .custom-options .MuiSelect-select {
  color: white !important;
  font-size: 1.6rem;
  padding-top: 5px;
  padding-bottom: 5px;

}
.confirm-buttons {
  position: relative;
}
.BatchDevices {
  margin-top: 30px;
}
.confirm-buttons .options {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right:0px;
  margin-top: 30px;
}
.confirm-buttons .section{
  background-color: $primary-flat;
  border-radius: 0px 3px 3px 0px;
  width: 45px;
  display: grid;
  place-items: center;
  cursor: pointer;
  border-left: 1px solid;
}
.confirm-buttons .section > svg {
  color: white;
  height: 1.6rem;

}
.custom-options .select-button .MuiSelect-select{
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-right: 14px !important;
}

.custom-options span svg {
  color: white;
  margin-left: 20px;
}
.custom-options .MuiSelect-select {
  margin-right: 0;
}
.MuiMenuItem-root.batch-options  {
  justify-content: space-between;
}
.MuiMenuItem-root.batch-options  > span{
  width: 120px;
}

.select-button {
  min-width: 150px;
}

#batch-confirm-screen.recipeTitle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#batch-confirm-screen.recipe-bar {
  margin-bottom: 10px;
}

#batch-confirm-screen .recipeDetails {
  border: dashed 1px #c7c7c7;
  padding: 10px;
  margin-top: -0px
}
#batch-confirm-screen .recipeDetails .inner{
  margin-top: -40px
}
#batch-confirm-screen.recipeDetails .title1{
  background: white;
  width: fit-content;
}

#batch-confirm-screen.recipeDetails .addConditionButton{
  margin: 0px 85px 0px auto;
  width: 200px;
}

#batch-confirm-screen.recipeDetails .addConditionButton{
  margin: 0px 85px 0px auto;
  width: 200px;
}

#batch-confirm-screen.recipeCondition {
  margin: 20px 30px;
  display: flex;
}

#batch-confirm-screen.select-recipe-setting {
  width: 380px;
}

#batch-confirm-screen.recipeCondition .comparison{
  margin: 0px 100px;
}

#batch-confirm-screen.recipeCondition .input{
  width: 350px;
  margin-top: -7px;
  float: right;
  margin-left: auto;
  margin-right: auto;
}

#batch-confirm-screen.recipeCondition .buttonRow{
  margin-left: auto;
  display: flex;
  margin-top: -10px;
}
#batch-confirm-screen.recipeCondition .buttonRow .deleteIcon{
  font-size: 24;
  margin-right: 25px
}

.div-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.setting-mismatch {
    width: 100%
  }
  .modal.setting-mismatch {
    width: 80vw;
  }

  .setting-mismatch .tableWrapper {
    max-height: 400px;
    overflow-y: scroll;
  }

  .setting-mismatch .buttonWrap {
    margin-left: auto;
    margin-right: 0px;
    width: 150px;
  }

  .settings-modal-top-bar {
    display: flex;
  }
  .settings-modal-top-bar h2 {
    flex: 1;
  }

  .setting-mismatch .MuiTypography-root  {
    color: $primary-flat;
    font-size: 16px;
  }

  .error-actions {
    display: flex !important;
  }

  .error-actions {
    justify-content: flex-end !important;
    width: 100% !important;
    margin-left: auto !important; // This will move the innermost div to the right
  }

  .error-actions .button-icon {
    color: $error;
  }

  .recipe-value {
    font-weight: bold;
    font-style: italic;
    margin-right: 5px;
    margin-left: 5px;
}

.row-error-warn {
  background-color: $warning !important; /* Light red background for error */
  color: #333 !important; /* Black text color */
  font-weight: 600 !important; /* Use a medium or semi-bold font weight */
}

.row-error-warn a {
  color: #1565c0 !important; /* Adjusted link color for better blending */
  transition: color 0.3s, font-weight 0.3s!important; /* Smooth transition for link color and font weight change */
}

.row-error-warn a:hover {
  color: #0d47a1 !important; /* Darker link color on hover */
}

.row-error-warn .MuiCheckbox-root {
  color: #333; /* Adjust the color for the unchecked checkbox */
}

.row-error-warn .Mui-checked {
  color: #0d47a1; /* Adjust the color for the checked checkbox */
}
.row-error {
  background-color: $error-light !important; /* Light red background for error */
  color: #333 !important; /* Black text color */
  font-weight: 600 !important; /* Use a medium or semi-bold font weight */
}

.row-error a {
  color: #1565c0 !important; /* Adjusted link color for better blending */
  transition: color 0.3s, font-weight 0.3s!important; /* Smooth transition for link color and font weight change */
}

.row-error a:hover {
  color: #0d47a1 !important; /* Darker link color on hover */
}

.row-error .MuiCheckbox-root {
  color: #333; /* Adjust the color for the unchecked checkbox */
}

.row-error .Mui-checked {
  color: #0d47a1; /* Adjust the color for the checked checkbox */
}
