.minor-stat {
  font-size: 11pt;
}

.minor-stat .stat {
  text-align: left;
  display: inline-block;
  width: 20%;
  font-weight: bold;
}

.minor-stat .stat-title-container {
  width: 80%;
  padding: 5px 20px;
  text-align: left;
  display: inline-block;
}

.minor-stat .stat-title {
  display: inline-block;
  text-align: left;
}
