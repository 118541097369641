#integrations button.integration-action  {
  float: right;
  padding: 2px 0 0 6px;
}

#integrations .integration {
  text-align: left;
}

#integrations h4 {
  margin-bottom: 20px;
}

#integrations .integration p {
  font-size: 10pt;
  margin: 4px 0;
}

#integrations .inline-label {
  display: flex;
}
