@import 'src/style/variable/variables.module';
.location-modal {
  max-width: 425px;
}
.location-modal .form-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.location-modal .textInput{
  width: 120px
}
.location-modal .link{
  float: right;
}
.location-modal .error-text{
  padding-top: 50px;
}
.location-modal .icon-container{
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
}
