@import 'src/style/variable/variables.module';

#device-screen .MuiPaper-root > .rdt_TableHeader {
  display: none;
}

#device-screen .data-table {
  margin-top: 20px;
}

#device-screen .child-devices-grid {
  width: 100%;
}

#device-screen .expandable {
  cursor: pointer;
}
#device-screen .expand-arrow {
  font-size: 10pt;
}
#device-screen .freshness {
  font-size: 0.7em;
}

#device-screen .device-title{
  text-align: center;
}
#device-screen .top-action-buttons {
  float: right;
  margin-top: -72px;
  display: flex;
  align-items: center;
}
#device-screen .device-screen-top-button {
  z-index: 1;
  height: 25px;
  width: 25px;
  padding: 1px 5px;
  background-color: $primary;
  border-radius: 5px;
  cursor: pointer;
  margin-left: 10px;
}
#device-screen .device-live-indication {
  height: 25px;
  width: 55px;
  background-color: $success;
  margin-left: 10px;
  border-radius: 5px;
  padding: 1px;
  font-size: small;
  display: flex;
  z-index: 1;
  cursor: default;
}
#device-screen .make-button-green {
  background-color: $success;
}
#device-screen .make-button-grey {
  background-color: $grey;
}
#device-screen .device-screen-live-title {
  font-weight: bold;
  color: $white;
}
#device-screen .loading-overlay {
  position: fixed;
  top: 200px;
  float: right;
  left: 97%;
  z-index: 2;
}

#device-screen .device-screen-top-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

#device-screen .prev-next-button {
  color: $black;
  z-index: 1;
  cursor: pointer;
  padding: 0px 20px;
}

#device-screen h2 {
  font-weight: bold;
}


@media (max-width: $mobile-width) {
  #device-screen .mainGrid {
    padding-top: 0;
    margin-top: 6px;
  }
  #device-screen .top-action-buttons{
    margin-top: 0px;
  }
}
