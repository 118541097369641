@import 'src/style/variable/variables.module';
.device-menu-container {
  width: 400px;
  height: calc(100% + 48px);
  color: $primary-flat;
  margin: -24px 0 -24px -16px;
  padding-left: 16px;
}

.device-menu-container > .loading {
  align-self: center;
  text-align: center;
  margin-top: 60px;
}

.device-menu-container .MuiTreeItem-content {
  height: 40px;
  padding-left: 24px;
}

.device-menu-container>ul {
  margin-left: -16px;
  overflow-y: auto;
}

.device-menu-container ul>li ul {
  margin-left: 0px;
}

.device-menu-container .tree-item-custom-label {
  line-height: 40px;
  display: flex;
  align-items: center;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  justify-content: space-between;
}

.device-menu-container .tree-item-custom-label > div {
  width: 40px;
}

.device-menu-container .tree-item-custom-label  > span {
  width: 300px;
  height: 35px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.tree-item-custom-label>svg {
  margin: 0 0 0 auto;
  display: block;
}

.device-menu-container .device-menu-search {
  display: flex;
  padding: 5px;
  display: flex;
  align-items: center;
}

.device-menu-search .search-field {
  width: 300px;
}

.device-menu-search > svg,
.filterIconContainer {
  margin: 0 0 0 41px;


  display: block;
}

.filterIcon{
  cursor: pointer;
}

.device-menu-container .alarm-badge{
  width: 50px;
  padding: 9px;
  margin-right: 0;
  margin-right: 60px;
}

.device-menu-container .alarm-badge .MuiSvgIcon-root {
  color: $quaternary-flat;
  margin-left: 5px;
}

.device-menu-container .MuiBadge-standard {
  font-size: 12px;
}

.device-menu-container .filterIconButton{
  min-width: 15px;
  padding: 5px;
}

.device-menu-container .filterIconContainer > .MuiBadge-standard {
  color: $primary-solid;
  background-color: $warning;
}


.menuList {
  width: 200px;
}

.actionButtons {
  width: 100%;
  margin: 15px 0px;
  display: flex;
  flex-wrap: wrap;
  padding-right: 15px;
}

.actionButtons > button {
  width: fit-content;
  margin: 10px;
  display: flex;
}

.breadCrumbs.MuiTypography-root  {
  color: $primary-flat;
  font-size: 15px;
  cursor: pointer;
}

.MuiBreadcrumbs-separator {
  color: $primary-flat;
}

.breadcrumb-contrainer {
  padding: 12px;
}

.confirm-modal.loading {
  margin-top: 120px;
}

.confirm-modal .circular-loading {
  margin: 30px auto;
  width: 45px;
  display: block;
}

.loadingDeviceTable {
  td {
    text-align: center;
  }
}
.tableWrap          { overflow: auto; height: 150px; }
.tableWrap thead th { position: sticky; top: 0; z-index: 1; background-color: $primary-surface;}


@media (max-width: $mobile-width) {
  .device-menu-container {
    width: auto;
  }
  .device-menu-search .search-field {
    width: 180px;
  }
  .actionButtons {
    margin-top: 10px;
    padding-bottom: 20px;
  }
  .device-menu-container h3{
    color: $primary-solid;
  }
  .device-menu-container .device-menu-search {
    justify-content: right;
  }
}
