.alarm-note-modal {
  height: 300px;
  width: 420px;
}

.alarm-note-modal button.large-btn {
  margin: 8px;
  width: 140px;
  height: 60px;
  text-align: center;
  padding: 0;
}

.alarm-note-modal button.large-btn.centered {
  margin-top: 25px;
  width: 295px;
  height: 40px;
}

.alarm-note-modal .modal-field{
  display: flex;
  justify-content: space-between;
}

.alarm-note-modal .modal-label {
  display: inline-block;
  padding-top: 12px;
  margin-top: 10px;
  margin-right: 15px;
  margin-bottom: 15px;
}

.alarm-note-modal .modal-input {
  width: 200px;
  margin: 12px 0px;
}

.alarm-note-modal .dismiss-form {
  margin: 20px 20px 0 20px;
}

.alarm-note-modal .expiry-form {
  margin-top: 10px;
}

.alarm-note-modal div.MuiOutlinedInput-root {
  margin-top: 0px;
}

.alarm-note-modal .button-bar {
  margin: 10px;
}
