.service-agreement-page h4 {
  font-weight: 400;
}

.service-agreement-container {
  padding: 8px 16px;
}

.service-agreement-page li:not(:last-child) {
   margin-bottom: 10px;
}

/*overrides settings from settings-modal and modal class */
.service-agreement-modal {
  width: 70% !important;
  padding: 0px !important;
}

.service-agreement-modal.MuiGrid-root {
  margin:  0px !important;
  padding: 0px !important;
}

.terms-container-paper div {
  min-height: 100%;
  padding: 20px;
}
