.formControl {
  width: 300px
}
.selectAllText {
  font-weight: 500
}
.selectedAll {
  background-color: rgba(0, 0, 0, 0.08)

}
.selectedAll:hover {
  background-color: rgba(0, 0, 0, 0.08)
}

.MuiMenu-paper {
  max-height: 224px;
}
