.device-selector {
  width: 400px;
  margin-top: 41px;
}
.modbus-send-button{
  margin-top: 45px;
}
.response-title{
  text-align: center;
}
.format-selector{
  position: absolute;
  top:10px;
  right:10px;
}
.response-paper {
  position: relative;
}
.binary-value {
  font-family: Consolas, 'Courier New', monospace;
}
