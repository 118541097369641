$primary-solid: #4a4a4a;
$primary-surface: #f2f5f7;

$error: #e81e4f;
$error-light: #f5a4bf;

$warning: #fcd54c;
$warning-light: #f9e77f;
$warning-dark: #b9930d;

$success: #97cd4e;
$success-light: #dce57f;

$info: #009dc1;
$info-light: #a0dbeb;

$input-background: #d9d9d9;
$input-border: #c7c7c7;

$black: #000;
$white: #fff;
$grey: #7f8080;
$grey-dark: #4f4f4f;
$grey-light: #c7c7c7;
$grey-faint: #eeeeee;

$primary: #4a41c5;
$primary-light: rgba(74, 65, 197, 0.486);
$primary-flat: #4a41c5;
$primary-dark: #3c34a7;
$primary-flat-95: rgba(74, 65, 197, 0.95);

$first: #f26324;
$first-light: #FFA07A;
$first-flat: #FF4500;
$first-dark: #D32F2A;
$first-flat-95: rgba(255, 87, 51, 0.95);

$secondary: #9cc93b;
$secondary-light: #dce57f;
$secondary-flat: #7cb62c;
$secondary-dark: #5c9014;

$tertiary: #f66633;
$tertiary-light: #fdc781;
$tertiary-flat: #d8400c;
$tertiary-dark: #aa2c01;

$quaternary: #fad130;
$quaternary-light: #f9e77f;
$quaternary-flat: #ebbe22;
$quaternary-dark: #b9930d;

$quinary: #e21b4e;
$quinary-light: #f5a4bf;
$quinary-flat: #c40233;
$quinary-dark: #9a0027;

$senary: #7952b3;
$senary-light: #ad8ed9;
$senary-flat: #633c8f;
$senary-dark: #4d3470;

$septenary: #FF6082;  // Pinkish hue
$septenary-light: #FFA6BF;  // Adjusted color
$septenary-flat: #E5739E;  // Adjusted color
$septenary-dark: #B35978;  // Adjusted color

$octonary: #00bcd4;
$octonary-light: #80deea;
$octonary-flat: #0097a7;
$octonary-dark: #007c91;

$nonary: #9C27B0;
$nonary-light: #BA68C8;
$nonary-flat: #8E24AA;
$nonary-dark: #7B1FA2;
$nonary-flat-95: rgba(156, 39, 176, 0.95);


$violet: #7200ca;

$gauge-status: #17c3b2;
$gauge-safe-range: #FFCB77;
$gauge-danger: #FE6D73;

/* new gauge colors */
$gauge-red: #FC6863;

// other variables
$mobile-width: 900px;
$phone-width: 600px;


:export {
  primarySolid: $primary-solid;
  primarySurface: $primary-surface;

  error:  $error;
  errorLight: $error-light;

  warning:  $warning;
  warningLight: $warning-light;
  warningDark: $warning-dark;

  success:  $success;
  successLight: $success-light;

  info:  $info;
  infoLight: $info-light;

  inputBackground: $input-background;
  inputBorder: $input-border;

  black:  $black;
  white:  $white;
  grey:  $grey;
  greyDark: $grey-dark;
  greyLight: $grey-light;
  greyFaint: $grey-faint;

  primary:  $primary;
  primaryLight: $primary-light;
  primaryFlat: $primary-flat;
  primaryDark: $primary-dark;
  primaryFlat95: $primary-flat-95;

  secondary:  $secondary;
  secondaryLight: $secondary-light;
  secondaryFlat: $secondary-flat;
  secondaryDark: $secondary-dark;

  tertiary:  $tertiary;
  tertiaryLight: $tertiary-light;
  tertiaryFlat: $tertiary-flat;
  tertiaryDark: $tertiary-dark;

  quaternary:  $quaternary;
  quaternaryLight: $quaternary-light;
  quaternaryFlat: $quaternary-flat;
  quaternaryDark: $quaternary-dark;

  quinary:  $quinary;
  quinaryLight: $quinary-light;
  quinaryFlat: $quinary-flat;
  quinaryDark: $quinary-dark;

  violet: $violet;

  gaugeStatus: $gauge-status;
  gaugeSafeRange: $gauge-safe-range;
  gaugeDanger: $gauge-danger;

  gaugeRed: $gauge-red;

  mobileWidth: $mobile-width;
  phoneWidth: $phone-width;

  first: $first;
  firstLight: $first-light;
  firstFlat: $first-flat;
  firstDark: $first-dark;
  firstFlat95: $first-flat-95;

  senary: $senary;
  senaryLight: $senary-light;
  senaryFlat: $senary-flat;
  senaryDark: $senary-dark;

  septenary: $septenary;
  septenaryLight: $septenary-light;
  septenaryFlat: $septenary-flat;
  septenaryDark: $septenary-dark;

  octonary: $octonary;
  octonaryLight: $octonary-light;
  octonaryFlat: $octonary-flat;
  octonaryDark: $octonary-dark;

  nonary: $nonary;
  nonaryLight: $nonary-light;
  nonaryFlat: $nonary-flat;
  nonaryDark: $nonary-dark;
  nonaryFlat95: $nonary-flat-95;
}
