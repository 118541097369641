#admin-screen .highlightable {
  &:hover {
    background-color: lightgray !important; /* Apply light gray color on hover */
  }
}

#admin-screen .buttons {
  width: 100%;
  justify-content: right;
  display: flex;
}

#admin-screen .header {
  margin-top: 16px;
}

#admin-screen .manage-client-button {
  padding: 10px;
}

#admin-screen {
  .MuiGrid-item {
    width: 100%;
  }
}
