#recurrence-rule-picker .MuiTextField-root {
  width: 60px;
}

#recurrence-rule-picker .MuiSelect-root {
  padding-left: 20px;
  padding-right: 28px;
}

#recurrence-rule-picker .MuiTextField-root input {
  padding-left: 14px;
  text-align: center;
}

#recurrence-rule-picker .option-line {
  margin-left: 24px;
}

#recurrence-rule-picker .option-line span {
  font-size: 12pt;
  vertical-align: middle;
  line-height: 32px;
}

#recurrence-rule-picker .spacer {
  width: 20px;
  display: inline-block;
}
