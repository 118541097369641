@import 'src/style/variable/variables.module';

.subnavbar {
  font-size: 10pt;
  padding: 0;
  width: 208px;
  height: fit-content;
}

.subnavbar-label {
  padding: 4px 8px 8px;
  color: $primary-solid;
  font-weight: bold;
}

a.subnavbar-item {
  padding: 12px;
  border-top: 1px solid $input-border;
  color: $primary-solid;
  text-decoration: none;
  text-align: left;
  display: block;
  white-space: nowrap;
}

.subnavbar > a.subnavbar-item:first-of-type {
  border: 0;
}

a.subnavbar-item > svg {
  margin: 0 6px 0 0 ;
  vertical-align: middle;
}

a.subnavbar-item:hover{
  color: $secondary;
}
