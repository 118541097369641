@import 'src/style/variable/variables.module';

.radial-gauge-container {
  margin: 15px auto;
  width:  220px;
  position: relative;
  text-align: center;
}
.gauge-info {
  cursor: pointer;
  position: absolute;
  top: 3px;
  left: -5px;
}

.radial-gauge-svg {
  overflow: visible;
  width: 150px;
  text-align: center;
  margin: 0 auto;
}

.radial-gauge-title {
  font-weight: bold;
  margin-bottom: 30px;
}

.radial-gauge-hamburger {
  cursor: pointer;
  position: absolute;
  top: 3px;
  right: 0;
}
.gauge-set-point {
  fill: $gauge-red;
  stroke: $black;
}
.gauge-background {
  stroke: $black;
}
.gauge-dial-arc {
  fill: #AAAAAA
}
