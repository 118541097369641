#report .editable-cell.revision-cell input {
  width: 70px;
}

#report .editable-cell.description-cell input,
#report .editable-cell.note-cell input {
    width: 170px;
}

#report .rdt_TableHeader {
  min-height: inherit;
}
