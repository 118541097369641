@import 'src/style/variable/variables.module';

.change-sub-modal {
  background-color: unset;
  border: unset;
  box-shadow: unset;
  width: 850px;

}
.change-sub-modal-after {
  width: 850px;
}
.change-sub-modal .options{

  display: flex;
  justify-content: space-between;
}
.change-sub-modal .options > div {
  padding: 20px;
  width: 250px;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  justify-content: space-between;
  font-weight: bold;
  background-color: white;
}


@media (max-width: $mobile-width) {
  .change-sub-modal.modal{
    width: max-content;
  }
  .change-sub-modal .options {
    flex-direction: column;
    height: 70vh;
    width: max-content;
  }
  .change-sub-modal-after {
    width: 95vw;
  }
}
