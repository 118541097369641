@import 'src/style/variable/variables.module';
.responsive-toggle-group .MuiToggleButtonGroup-root button {
  width: 50px;
  line-height: 9px;
  padding: 15px 5px;
}
.responsive-toggle-group {
  display: flex;
  text-align: space-around;
  padding: 5px;
  position: relative;
}
.responsive-toggle-group .unit-buttons{
  flex: 1;
  text-align: center;
  padding: 0px 10px;
}
.responsive-toggle-group .responsive-toggle-group-badge {
  height: 40px;
}
.filter-group .filters-container {
  width: inherit;
  justify-content: space-evenly;
}
.filter-button {
  position: absolute;
  right: 10px;
}
@media (max-width: $mobile-width) {
  .responsive-toggle-group .unit-buttons{
    padding: 0px 40px;
  }
}
