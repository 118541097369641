.settings-pane {
  max-width: 420px;
  margin-bottom: 30px;
}

.settings-pane .MuiPaper-root {
  text-align: left;
  margin-bottom: 24px;
}

.settings-pane .description {
  margin-bottom: 30px;
}

.settings-pane .value {
  min-height: 40px;
  padding: 4px;
}
