.major-stat {
  width: 120px;
  display: inline-block;
  margin: 10px 5px;
}

.major-stat .stat {
  padding: 0 10px;
  text-align: center;
  vertical-align: middle;
  font-size: 2.4em;
  font-weight: 600;
}

.major-stat .stat-title {
  padding: 0.4rem;
  border: 0;
  font-size: 9pt;
  font-weight: 600;
  height: 65px;
}
