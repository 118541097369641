@import 'src/style/variable/variables.module';

.child-devices h3 {
  margin-bottom: 20px;
}

.child-devices tr.MuiTableRow-root:not(tr.MuiTableRow-head):hover {
  background-color: rgba(0, 0, 0, 0.12);
}

.child-devices .delete-actions button {
  padding: 0;
}

.line-through {
  text-decoration: line-through;
  color: $grey;
}

.loading-overlay {
  top: 200px;
  float: right;
  left: 97%;
}

.box {
  display: flex;
  justify-content: center;
}
