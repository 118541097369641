@import 'src/style/variable/variables.module';

.filter-container{
  display: flex;
  border: 1px solid $primary-dark;
  border-radius: 5px;
  width: fit-content;
  background-color: $primary-dark;;
  margin: 5px;
}

.filter-container .value {
  width: fit-content;
  background-color: $white;
  border-radius: 0px 5px 5px 0px;
  line-height: 25px;
  padding-right: 5px;
  height: fit-content;
}
.filter-container .value .text {
  color: $primary-dark;
  padding-left: 10px;
  padding-right: 5px;
}
.filter-container .value >  div {
  padding: 1px;
}
.filter-container .label {
  width: fit-content;
  height: 25px;
  line-height: 25px;
  text-align: center;
  font-size: 12px;
  color: $white;
  padding: 0px 11px;
  background-color: $primary-dark;

}
.filter-container .MuiOutlinedInput-root {
  margin-top: 0px !important;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.filter-container .MuiAutocomplete-inputRoot,
.filter-container .MuiAutocomplete-input,
.filter-container .MuiAutocomplete-root {
  height: 23px;
  border: none;
  padding-bottom: 0px !important;
  padding-top: 0px !important;
  width: fit-content !important;
}
.filter-container .MuiAutocomplete-root {
  background: $white;
  padding: 0px !important;
}
.filter-container .MuiOutlinedInput-root.MuiAutocomplete-input {
  padding: 0px !important;
}
.MuiInputBase-input {
  padding-bottom: 0px;
  padding-top: 0px;
  color: $primary-dark !important;
}
.icon {
  cursor: pointer;
}
.filter-container .MuiSvgIcon-root {
  fill: $primary-dark !important;
  margin-top: 4px;
}
