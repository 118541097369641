@import 'src/style/variable/variables.module';

#profile .css-gbnloz-MuiInputBase-root-MuiInput-root:before{
  border-bottom: none;
}
#profile .css-gbnloz-MuiInputBase-root-MuiInput-root:after {
  border-bottom: none;
}
#profile .contact-number-label {
  font-size: 12px;
  font-weight: bold;
  color: rgba(0,0,0,0.6);
  text-transform: uppercase;
}
#profile .contact-number-label-focused {
  font-size: 12px;
  font-weight: bold;
  color: $primary;
  text-transform: uppercase;
}
#profile .css-gbnloz-MuiInputBase-root-MuiInput-root {
  border: 0.5px solid $input-border;
  padding: 13px;
  border-radius: 4px;
}
#profile .css-gbnloz-MuiInputBase-root-MuiInput-root:hover {
  border-color: $black;
}
#profile .css-gbnloz-MuiInputBase-root-MuiInput-root:focus {
  border: 2px solid $primary;
}
