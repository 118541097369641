.st-logo.spinner .st-logo-line {
  animation: logo_spinner_line_s_do 2000ms linear infinite normal forwards
}

@keyframes logo_spinner_line_s_do {
  0% {
    stroke-dashoffset: 1085.38
  } 100% {
    stroke-dashoffset: -1085.38
  }
}


.path {
  opacity: 0;
  animation: fadeIn 2s ease-in-out infinite;
}
@keyframes fadeIn {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 0; }
}
.delay1 { animation-delay: 0s; }
.delay2 { animation-delay: 0.5s; }
.delay3 { animation-delay: 1s; }
.delay4 { animation-delay: 1.5s; }
.delay5 { animation-delay: 2s; }
.delay6 { animation-delay: 2.5s; }
.delay7 { animation-delay: 3s; }
.delay8 { animation-delay: 3.5s; }
