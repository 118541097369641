@import 'src/style/variable/variables.module';
.settings-modal .select-field {
  width: 100%;
}
.select-field .MuiInputLabel-root {
  margin-bottom: -4.5px;
}
.settings-modal {
  overflow-y: auto;
  min-height: 30%;
  max-height: 75%;
  width: 475px;
  overflow-x: hidden;
}
@media (max-width: $mobile-width) {
  .settings-modal {
    width: calc(100vw - 20px);
  }
}

.settings-modal .MuiGrid-root {
  margin: 5px 0px;
  padding: 0px 15px 5px 5px;
}

.settings-modal input:disabled {
  background-color: $grey-faint;
}
.settings-modal .MuiSelect-select.Mui-disabled {
  background-color: $grey-faint;
}
.settings-modal .settings-modal-top-bar {
  display: flex;
  text-align: space-around;
}
.settings-modal .settings-modal-title {
  flex: 1;
  text-align: center;
}

.settings-modal .text-field-label{
  white-space:wrap;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
}

.settings-modal .select-field .MuiInputLabel-root {
  margin-top: 0px;
}
