
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.qrcode {
  margin: 10px auto;
}
.secret {
  margin-left: -135px;
}
.otpButton {
  margin-top: 15px;
}

.textSubmitButton {
  margin: auto;
  margin-top: 20px;
  width: 100px;
}


.vi__character {
  border-width: 1px;
  border-color: #3c3c414a;
  font-size: 20px;
  border-radius: 8px !important;

  background-color: white;
  box-shadow: 2px 2px 0 #e4e2f5;


}

.codeArea {
  margin: auto;
  width: 300px;
}


input.vi {
  opacity: 0;
}
