.add-lookup-item-modal {
  height: 360px;
}

.add-lookup-item-modal button {
  float: right;
  margin-top: 30px;
}

.add-lookup-item-modal .MuiTextField-root {
  float: left;
}
