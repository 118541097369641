#weather-forecast {
  margin: 10px;
}
#weather-forecast h3{
  margin: 10px;
}
#weather-forecast .weather{
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
}
