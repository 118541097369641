#report-schedule .MuiSelect-root {
  padding-left: 20px;
  padding-right: 28px;
}

#report-schedule .month-selector {
  margin-right: 20px;
}

#report-schedule .form-sub-label {
  padding: 0 20px;
}

#report-schedule .MuiPaper-root {
  padding: 30px;
}

#report-schedule button.schedule-action  {
  float: right;
  padding: 2px 0 0 6px;
}

#report-schedule .editable-cell-label  {
  font-size: 20pt;
}
