#alarm-rules-screen .ruleTitle {
  width: 100%;
}
#alarm-rules-screen .ruleTitle td{
  font-size: 1.6rem;
}

#alarm-rules-screen  .ruleTitle .ruleTitleText{
  display: flex;
}
#alarm-rules-screen  .ruleTitle .ruleNumber{
  font-weight: bold;
  width: 120px;
}
#alarm-rules-screen  .ruleTitle .generator{
  display: flex;
  flex-wrap: wrap;
  margin: 0px;
  margin-top: 5px;
}

#alarm-rules-screen  .ruleTitle .generatorInner{
  overflow-wrap: inherit;
  margin-left: 5px;
}

#alarm-rules-screen .rule-bar {
  margin-bottom: 10px;
}

@media screen and (max-device-width: 775px) {
  #alarm-rules-screen  .ruleTitle .ruleNumber{
    display: none;
  }
}

#alarm-rules-screen  .ruleTitle .ruleTitleButtons{
  display: flex;
  height: 100%;
}
#alarm-rules-screen  .ruleTitle .ruleTitleButtons .deleteButton{
  height: 20px;
  margin: auto;
  padding: 0;
}


#alarm-rules-screen  .ruleDetails {
  border: dashed 1px #c7c7c7;
  padding: 10px;
  margin-top: -0px
}
#alarm-rules-screen  .ruleDetails .inner{
  margin-top: -40px
}
#alarm-rules-screen .ruleDetails .title1{
  background: white;
  width: fit-content;
}

#alarm-rules-screen .ruleDetails .addConditionButton{
  margin: 0px 85px 0px auto;
  width: 200px;
}

#alarm-rules-screen .ruleDetails .addConditionButton{
  margin: 0px 85px 0px auto;
  width: 200px;
}
#alarm-rules-screen .ruleDetails .title2{
  display: flex;
}

#alarm-rules-screen .ruleDetails .title2 .line{
  border-top: dashed 1px  #c7c7c7;
  background: none;
  margin-left: -10px;
  margin-top: 30px;
  margin-right: 0px;
  width: 10px;
}

#alarm-rules-screen .ruleCondition {
  margin: 20px 30px;
  display: flex;
}

#alarm-rules-screen .ruleCondition .comparison{
  display: flex;
  margin: 0px 100px;
}

#alarm-rules-screen .ruleCondition .input{
  width: 500px;
  margin-top: -7px;
}

#alarm-rules-screen .ruleCondition .select{
  width: 500px;
  height: fit-content;
}
#alarm-rules-screen .ruleCondition .buttonRow{
  margin-left: auto;
  display: flex;
  margin-top: -10px;
}
#alarm-rules-screen .ruleCondition .buttonRow .deleteIcon{
  font-size: 24;
  margin-right: 25px
}

#alarm-rules-screen .notifications-container .MuiSwitch-root {
  margin-top: 13px;
}

#alarm-rules-screen .helper-tool-tip {
  margin-left: 5px;
  cursor: default;
}
