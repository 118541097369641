#device-properties th {
  text-align: left;
}
#device-properties .device-info thead tr > th{
  font-size: 1.6rem;
  font-weight: bold;
  padding: 5px 0px;
}

#device-properties .editable-cell .MuiFormControl-root {
  width: 75%;
}

#device-properties table{
  table-layout: fixed;
}

#device-properties td{
  word-wrap: break-word;
}
