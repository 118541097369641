@import 'src/style/variable/variables.module';

.navbar-drawer, .navbar-drawer>.MuiPaper-root {
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  flex-shrink: 0;
  width: 245px;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  white-space: nowrap;
  box-sizing: border-box;
  -webkit-transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  overflow-x: hidden;
  background: $primary-flat;
}

.navbar-drawer.closed, .navbar-drawer.closed>.MuiPaper-root {
  width: 65px;
}

.navbar-collapse .MuiListItem-root {
  padding-left: 43px !important;
}

#navbar-upper .ehtgroup-logo {
  width: 100%;
}

#navbar-upper .ehtgroup-logo>img {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  display: block;
}

.navbar-item-icon-wrapper {
  width: 32px;
  min-width: 32px;
  height: 32px;
  padding: 4px 1px;
}

.navbar-item {
  cursor: pointer;
}

.MuiListItemText-primary,
.navbar-item-text {
  font-size: 11pt;
  white-space: nowrap;
  padding-left: 12px;
  overflow-x: hidden;
  width: 180px;
  min-width: auto;
  transition-property: width, min-width;
  transition-duration: 225ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

.navbar-item.main .navbar-item-text {
  padding-left: 4px;
  letter-spacing: 3px;
  margin-top: -6px;
  color: $white;
  width: 185px;
}

#navbar .navbar-item.main .navbar-item-text {
  width: 160px;
}

.navbar-item.main.whitelabel .navbar-item-text {
  height: 48px;
  min-width: 200px;
  padding-top: 16px;
  justify-content: space-between;
  display: flex;
  width: 200px;
}

.navbar-item.main .navbar-item-icon-wrapper svg {
  color: $white;
}

.navbar-item .block-letter {
  font-size: 125%;
  margin-bottom: 2px;
  font-weight: bolder;
}

.MuiModal-root .navbar-item.main .navbar-item-icon-wrapper {
  margin-top: 4px;
  margin-left: 4px;
  width: 42px;}

.MuiModal-root .block-letter {
  margin-top: 2px;
}

.navbar.drawer-closed .navbar-item-text {
  width: 0;
  min-width: 0;
}

a.navbar-item,
button.navbar-item {
  margin: 2px 0;
  display: block;
  position: relative;
  padding: 0;
  padding-left: 4px;
}

button.navbar-item span {
  margin-top: 0;
}

.navbar-drawer .MuiPaper-root {
  padding: 0px 0px;
}

#navbar-upper a.navbar-item,
#navbar-upper button.navbar-item,
#navbar-lower a.navbar-item,
#navbar-lower button.navbar-item {
  padding: 2px 4px;
}

#navbar-upper a.navbar-item.active,
#navbar-upper button.navbar-item.active,
#navbar-lower a.navbar-item.active,
#navbar-lower button.navbar-item.active {
  padding: 2px 4px 2px 0;
}

#navbar-upper {
  overflow-y: auto;
  overflow-x: hidden;
  min-height: 100px;
}

.navbar-catagory-header {
  color: white;
  text-align: center;
  font-weight: bold;
  width: fit-content;
  margin: auto;
}


.navbar-item.main .navbar-item-icon-wrapper {
  width: 48px;
  height: 48px;
  padding-left: 0;
  margin-left: -3px;
}


#navbar-lower {
  justify-self: flex-end;
  padding-bottom: 0px;
  margin-top: auto;
}

#navbar-lower > .MuiDivider-root {
  margin: auto 5px !important;
  background-color: $primary-light;
}

.bottom-navbar {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 55px;
  background-color: $primary-flat;
  display: flex;
  justify-content: space-around;
  z-index: 100;
}

.nav-popover-settings .MuiPopover-paper>.MuiDivider-root {
  background-color: $grey-faint;
}

#navbar-header .chevron {
  height: 20px;
  width: 20px;
  padding-top: 3px;
  margin-left: 9px;

  position: absolute;
  right: 10px;
}

:is(#navbar-upper,#navbar-lower,.nav-popover) a.navbar-item .navbar-item-icon-wrapper svg,
:is(#navbar-upper,#navbar-lower,.nav-popover) button.navbar-item .navbar-item-icon-wrapper svg,
:is(#navbar-upper,#navbar-lower,.nav-popover) a.navbar-item .navbar-item-text,
:is(#navbar-upper,#navbar-lower,.nav-popover)  button.navbar-item .navbar-item-text {
  color: $white;
  transition: opacity 100ms;
}

a.navbar-item:hover .navbar-item-icon-wrapper svg,
button.navbar-item:hover .navbar-item-icon-wrapper svg,
a.navbar-item:hover .navbar-item-text,
button.navbar-item:hover .navbar-item-text {
  opacity: 0.85;
}

a.navbar-item.active .navbar-item-icon-wrapper svg,
button.navbar-item.active .navbar-item-icon-wrapper svg,
a.navbar-item.active .navbar-item-text,
button.navbar-item.active .navbar-item-text {
  opacity: 1;
}

#navbar-header >.button:enabled:hover span {
  color:white;
}

a.navbar-item.main .navbar-item-icon-wrapper svg,
a.navbar-item.main .navbar-item-text,
a.navbar-item.main:hover .navbar-item-icon-wrapper svg,
a.navbar-item.main:hover .navbar-item-text {
  opacity: 1;
}

a.navbar-item.main:hover,
a.navbar-item.main.active,
a.navbar-item.disabled {
  background-color: inherit;
  border: none;
}

a.navbar-item.active {
  padding-left: 0px;
  border-left: 4px solid $white;
}

a.navbar-item.bottom-nav-item.active {
  color: $white;
  border: 0px;
  background: $primary-dark;
}

a.navbar-item.smallNav.active {
  background: $primary-dark;
}

a.navbar-item.main {
  padding: 0;
}

.nav-popover .MuiPopover-paper {
  background: $primary-flat-95;
  height: fit-content;
  padding: 0px;
  padding-top: 5px;
  overflow-y: hidden;
}

.navbar-item .MuiListItem-root {
  padding: 2px 10px 2px 16px;
}

.navbar-popup {
  left: 20px;
  width: 220px;
  padding: 0px;
  border: none;
}

@media (max-width: 640px) {
  #navbar-upper .main {
    display: none;
  }
}

@media (--print) {
  #navbar {
    display: none;
  }
}

/* width */
#navbar-upper::-webkit-scrollbar,
.navbar-drawer .MuiPaper-root::-webkit-scrollbar,
.nav-popover-settings .MuiPopover-paper::-webkit-scrollbar {
  width: 5px;
}

/* Track */
#navbar-upper::-webkit-scrollbar-track,
.navbar-drawer .MuiPaper-root::-webkit-scrollbar-track {
  background: $primary-flat;
}

/* Handle */
#navbar-upper::-webkit-scrollbar-thumb,
.navbar-drawer .MuiPaper-root::-webkit-scrollbar-thumb,
.nav-popover-settings .MuiPopover-paper::-webkit-scrollbar-thumb {
  background: $primary-dark;
  border-radius: 3px;
}

/* Handle on hover */
#navbar-upper::-webkit-scrollbar-thumb:hover,
.navbar-drawer .MuiPaper-root::-webkit-scrollbar-thumb:hover,
.nav-popover-settings .MuiPopover-paper::-webkit-scrollbar-thumb:hover{
  background: $grey-dark;
}

/* Settings popover */
.nav-popover-settings .MuiPopover-paper {
  background: white;
  height: fit-content;
  padding: 0px;
  padding-top: 5px;
  /* overflow-y: hidden; */
}

div.navbar-item .navbar-item-icon-wrapper svg,
div.navbar-item .navbar-item-text,
.nav-popover-settings  a.navbar-item .navbar-item-icon-wrapper svg,
.nav-popover-settings  button.navbar-item .navbar-item-icon-wrapper svg,
.nav-popover-settings  a.navbar-item .navbar-item-text,
.nav-popover-settings button.navbar-item .navbar-item-text {
  color: $primary-dark;
  opacity: 1;
}

.nav-popover-settings a.navbar-item.active {
  padding-left: 0px;
  border-left: 4px solid $primary-dark;
}

.nav-popover-settings .setting-popover-header {
  font-weight: bold;
  margin-left: 25px;
  display: flex;
  margin-top: 5px;
  align-items: center;
  width: 240px;
}

.nav-popover-settings  .user-settings {
  margin-bottom: 10px;
  margin-top: 10px;
  margin-left: 16px;
}

.user-details {
  line-height: 15px;
  padding-top: 3px;
}

.user-details .user-email {
  font-weight: normal;
  font-size: small;
}

.nav-popover-settings .user-initals{
  border-radius: 50%;
  width: 35px;
  height: 35px;
  margin: auto 14px auto 0px;
  text-align: center;
  background: $primary;
  color: white;
  padding: 4px;
}

@media (max-width: $mobile-width) {
  button.navbar-item.bottom-nav-item {
    background-color: $primary-dark;
    padding-bottom: 12px;
  }

  .bottom-navbar .navbar-item .MuiListItem-root {
    padding: 4px 16px;
    height: 54px;
  }

  a.navbar-item, button.navbar-item {
    padding-left: 0px;
  }

  .navbar-item-icon-wrapper {
    padding: 2px 1px;
  }

  a.navbar-item,
  button.navbar-item {
    margin: 0;
  }
}

.navbar-item-icon-wrapper .atcom-logo-sq {
  margin-top: 10px;
  margin-left: 4px;
}
.MuiDrawer-modal .navbar-item-icon-wrapper .atcom-logo-sq {
  margin-left: 1px;
}
.navbar-item-text .atcom-logo-text {
  margin-top: -8px;
}
