.labelledSwitch {
  width: 28;
  height: 16;
  padding: 0;
  display: 'flex'
}
.labelledSwitch :active .MuiSwitch-thumb {
    width: 15;
}
.labelledSwitch :active .MuiSwitch-switchBase .Mui-checked {
    transform: translateX(9px);
}
.labelledSwitch .MuiSwitch-switchBase {
  padding: 2,
}
.labelledSwitch .MuiSwitch-switchBase .Mui-checked{
  transform: translateX(12px);
  color: '#fff';
}
.labelledSwitch .MuiSwitch-switchBase .MuiSwitch-track{
  opacity: 1;
  background-color:  '#1890ff';
}
.labelledSwitch  .MuiSwitch-thumb {
  box-Shadow: 0 2px 4px 0 rgb(0 35 11 / 20%);
  width: 12;
  height: 12;
  border-radius: 6;
  transform: translateX(-2px);
}
.labelledSwitch .MuiSwitch-track {
  border-radius: 16 / 2;
  opacity: 1;
  background-color:  rgba(0,0,0,.25);
  box-sizing: 'border-box';
}
.labelledSwitch .MuiTypography-root{
  font-size: 12px;
}
.labelledSwitch {
  display: flex;
}
.labelledSwitch h4 {
  font-weight: 500;
  padding-top: 3px;
  padding-right: 20px;
}
