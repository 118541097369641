#alarms-active .placeholder-graph img {
  opacity: 0.20;
}

#alarms-active .placeholder-graph {
  position: relative
}

#alarms-active .placeholder-graph-overlay {
  width: 100%;
  height: 100%;
  font-size: 2em;
}

#alarms-active .placeholder-graph-overlay .inner {
  width: 80%;
}

.alarmFilterButtons .ToggleButton-text {
  font-weight: bold;
  font-size: 10px;
}

.filterButton {
  margin-top: 15px;
  margin-right: 5px;
  height: 50px;
}
