@import 'src/style/variable/variables.module';

#programming-discrepancies .programming-actions button {
  float: right;
  padding: 0 4px 0 4px;
}

#programming-discrepancies .filter-container .value span {
  display: inline-flex;
  align-items: center;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#programming-discrepancies .filter-container .value span .text {
  display: inline-block;
}

#programming-discrepancies .filter-container .value span .icon {
  margin-left: 8px;
}

#programming-discrepancies .not-acknowledge-indication {
  cursor: pointer;
  text-align: center;
  border: 1px solid $tertiary;
  background-color: $tertiary;
  border-radius: 4px;
  width: 40px;
  font-weight: bold;
}

#programming-discrepancies .device-forced-on-indication {
  text-align: center;
  color: $success;
  width: 40px;
  font-weight: bold;
}

#programming-discrepancies .device-not-forced-on-indication {
  cursor: pointer;
  text-align: center;
  color: $error;
  width: 40px;
  font-weight: bold;
}

#programming-discrepancies .discrepancy-status {
  display: grid;
  grid-row-gap: 5px;
}


#programming-discrepancies .acknowledge-indication {
  cursor: pointer;
  text-align: center;
  border: 1px solid $quaternary;
  background-color: $quaternary;
  border-radius: 4px;
  width: 40px;
  font-weight: bold;
}
