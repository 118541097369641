.graph-options {
  display: flex;
  margin-right: 0px;
  margin-left: auto;
  width: 300px;
  justify-content: space-around;
}
.graph-options .MuiSelect-select{
  padding: 10px
}
