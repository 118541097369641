@import 'src/style/variable/variables.module';

#define-upgrade .page {
  height: 100%;
}

.define-upgrade-path-screen  {
  height: calc(100% - 90px);
}

#define-upgrade .define-upgrade-path-screen  .MuiPaper-root {
  min-width: fit-content;
  height: 100%;
}

#define-upgrade .define-upgrade-path-screen {
  display: flex;
  height: 100%;
  width: 100%;
}

#define-upgrade .define-upgrade-path-screen  {
  display: flex;
  flex-direction: column;
}

#define-upgrade .MuiSvgIcon-root.warningStatus {
  color: #fcd54c
}

#define-upgrade .actions {
  display: flex;
  width: 400px;
  margin: auto;
}

#define-upgrade .settings-panel-paper {
  min-height: 300px;
  padding-right: 4px;
}

#define-upgrade .recipe-select .MuiOutlinedInput-input {
  font-size: 14px !important;
  padding-bottom: 8px;
}

#define-upgrade .MuiPaper-root .MuiMenuItem-root {
  font-size: 14px !important;
}

#define-upgrade .recipe-toolbar {
  position: relative;
}

#define-upgrade .wrapper {
  display: flex; /* or grid */
  margin-top: 24px;
}

#define-upgrade .left {
  order: 1;
}

#define-upgrade .center {
  margin-left: 5px;
  order: 2;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 800px;
  padding-right: 8px;
  padding-left: 8px;
  width: 100%;
  max-width: 100%;
  display: flex;
}

#define-upgrade.page .table-filter .MuiTextField-root {
  margin-top: -75px;
  margin-bottom: 15px;
  max-width: 240px;
}

@media (max-width: $mobile-width) {

  .define-upgrade-path-screen {
    display: flex;
    flex-direction: column;
  }

  .define-upgrade-path-screen .MuiPaper-root {
    min-width: auto;
  }
}
