@import 'src/style/variable/variables.module';
.setting-mismatch {
  width: 100%
}

.modal.setting-mismatch {
  width: 60vw;
}

.setting-mismatch .MuiTableContainer-root {
  max-height: 400px;
  overflow-y: auto;
}


.settings-modal-top-bar {
  display: flex;
}

.settings-modal-top-bar h2 {
  flex: 1;
}


@media (max-width: $mobile-width) {
  .modal.setting-mismatch {
    width: 93vw;
  }

  .setting-mismatch .button-bar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
