@import 'src/style/variable/variables.module';

#devices .page {
  height: 100%;
}

.groupedDevicePage {
  height: calc(100% - 90px);
}

.groupedDevicePage .MuiPaper-root {
  min-width: fit-content;
  margin-top: 24px;
  height: 100%;
}

.grouped-device-screen {
  display: flex;
  height: 100%;
}
.MuiSvgIcon-root.warningStatus {
  color: #fcd54c
}

.grouped-device-screen .left {
  border-right: 1px solid $grey-light;
  height: calc(100% - 48px);
  margin: 24px 0;
  width: fit-content;
}

.grouped-device-screen .right {
  padding: 20px;
  width: 100%;
  min-width: 500px;
}

.actions {
  display: flex;
  width: 400px;
  margin: auto;
}


.recipe-select .MuiOutlinedInput-input {
  font-size: 14px !important;
  padding-bottom: 8px;
}

.MuiPaper-root .MuiMenuItem-root {
  font-size: 14px !important;
}

.recipe-toolbar {
  position: relative;
}

.grouped-device-screen .right {
  position: relative;
}

.grouped-device-screen .right .search-field {
  position: absolute;
  top: 100px;
  right: 20px;
}

.recipe-toolbar {
  margin-top: 10px;
}

.recipe-select  {
  min-width: 180px; /* Adjust the width as needed */
  // border-radius: 4px; /* Border radius */
  // padding: 8px; /* Padding */
  height: 40px;
  // min-width: 40px;
}

.recipe-toolbar .toolbar-item {
  margin-right: 10px; /* Adjust the margin as needed */
}

.apply-batch-button {
  height: 39px;
}

.apply-batch-button  {
    align-items: center;
}
/* To avoid margin on the last item */
.recipe-toolbar .toolbar-item:last-child {
  margin-right: 0;
}

/* Style the label */
// .customLabel {
//   marginBottom: 4px; /* Adjust the margin as needed */
// }

.grouped-device-controller-view .table .MuiTableCell-root {
  font-weight: 500;
}

.tab-label-size {
  font-size: medium;
  font-weight: bold;
}

#devices.page .table-filter .MuiTextField-root {
  margin-top: -75px;
  margin-bottom: 15px;
  max-width: 240px;
}
.warningIcon {
  background: $warning;
  cursor: pointer;
}
@media (max-width: $mobile-width) {

  .grouped-device-screen .right .search-field {
    position: absolute;
    top: 20px;
    right: 10px;
  }

  .grouped-device-screen {
    display: flex;
    flex-direction: column;
  }
  .groupedDevicePage .MuiPaper-root {
    min-width: auto;
  }

  #devices.page .table-filter .MuiTextField-root {
    margin-top: 0px;
    min-width: 180px;
  }
  #devices.page .table-filter form {
    height: 50px;
  }
  .grouped-device-screen .left {
    width: auto;
    border: none;
    margin-bottom: 10px;
  }
  .grouped-device-screen .right {
    padding: 10px 5px 20px 5px;
    width: 100%;
    min-width: 100%;
    overflow-y: unset;
  }
}
