.integration-modal {
  width: 560px;
}

.integration-modal .integration-modal-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.integration-modal button {
  margin: 8px;
  width: 140px;
  height: 90px;
  text-align: center;
  padding: 0;
}

.integration-modal .button-bar button {
  width: 70px;
  height: 20px;
  text-align: center;
  padding-bottom: 30px;
  padding-right: 30px;
}

.integration-modal {
  overflow-y: auto;
  min-height: 40%;
  max-height: 75%;
}
