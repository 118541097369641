.settings-modal .bits-field {
  width: 100%;
}
.settings-modal .bits-field-options {
  margin-top: 40px;
  font-size: 0.9em;
}
.settings-modal .bits-field-options label {
  display: inline-block;
  min-width: 50%;
  height: 36px;
}
.settings-modal .bits-field-options label .label {
  padding-top: 6px;
  display: inline-block;
}
.settings-modal .bits-field-options .MuiSwitch-root {
  float: left;
}
.settings-modal .unit {
  text-transform: none;
}
