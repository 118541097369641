@import 'src/style/variable/variables.module';

#configure-search-screen .add-search-field-form {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 20px;
}

#configure-search-screen .configure-search-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $grey-light;
}

#configure-search-screen .field-title {
  padding: 0px;
  margin: 0px;
}

#configure-search-screen .no-data-in-table {
  text-align: center;
  font-size: medium;
}

#configure-search-screen .title-and-icon {
  display: flex;
}
#configure-search-screen .title-and-icon p {
  margin: 0;
  font-size: 1.3rem;
}
