/* critical:start */
@import 'src/style/variable/variables.module';

* {
  box-sizing: border-box
}

html,
body,
#root,
#root > div[data-reactroot] {
  width: 100%;
  height: 100%;
  margin: 0;
}

html {
  font-size: 62.5%;
}

body {
  color: $primary-solid;
  font-size: 1.6rem;
  line-height: 1.8;
  font-family: 'Montserrat', 'Trebuchet MS', 'Arial', 'sans-serif';
  font-weight: 400;
}
/* critical:end */

input, body .MuiInputBase-input, button {
  font-family: 'Montserrat', 'Trebuchet MS', 'Arial', 'sans-serif';
  font-size: 1.2em;
}

body .MuiFormHelperText-root {
  font-size: 1.25rem;
}

body .MuiFormLabel-root {
  font-size: 1.5rem;
  max-width: 100%;
}

header > div {
  width: 100%;
}

h1,
h2 {
  font-weight: 400;
  margin: 5px 0 10px;
  padding: 0;
}

h3,
h4,
h5 {
  margin: 0;
  padding: 0;
  font-weight: 600;
}

h2 {
  font-size: 16pt;
}

h2, h3 {
  text-align: center;
}

.placeholder .rect-shape {
  background: $primary-solid;
}

hr {
  background-color: $grey-light;
  height: 1px;
  border: 0;
}

a,
a:link,
a:visited {
  color: $primary;
  text-decoration: none;
}

a:hover,
a:active,
a:focus {
  color: $violet;
  text-decoration: none;
}

.page {
  padding: 20px;
  min-height: 100%;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.float-center {
  text-align: center;
}

@media (--print) {
  @page { margin: 1.6cm; }
}

table {
  border-collapse: collapse;
}
table th,
table td {
  padding: 0.4rem;
  border: 0;
  font-size: 9pt;
}

table {
  width: 100%;
}

body .MuiTableCell-root {
  font-size: 9pt;
  padding-left: 5px;
  padding-right: 5px;
}

table.horizontal-headings th,
table.horizontal-headings td {
  font-size: 11pt;
}

table.horizontal-headings th {
  text-align: left;
  font-weight: 400;
}

table.horizontal-headings td {
  font-weight: 600;
  text-align: left;
}

td.emphasize {
  padding: 0 10px;
  text-align: center;
  vertical-align: middle;
  font-size: 2.4em;
  font-weight: 600;
}



figure {
  display: table;
  margin: 1rem auto;
}

figure figcaption {
  color: $grey;
  display: block;
  margin-top: 0.25rem;
  text-align: center;
}

code {
  background-color: $grey-light;
  border-radius: 3px;
  padding: 0.1rem 0.2rem;
}

.uppercase {
  text-transform: uppercase;
}

.monospace {
  font-family: monospace;
}

.pos-relative {
  position: relative;
}

.pointer-events-none {
  pointer-events: none;
}

.opacity-70 {
  opacity: 0.7;
}

@media print {
  .viewport-layout-main-pane-left {
    display: none;
  }
}

body .MuiTooltip-tooltip {
  font-size: 10pt;
  font-family: inherit;
}

.modal {
  width: 400px;
  max-height: 90vh;
  overflow-y: auto;
  margin: 100px auto;
  background-color: $primary-surface;
  border: 1px solid $grey-light;
  border-radius: 4px;
  box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12);
  padding: 16px 32px 8px;
}
@media (max-width: $mobile-width) {
  .modal {
    width: calc(100vw - 20px);
  }
  .page {
    padding: 10px;
    margin-bottom: 130px;
  }
}

@media (max-width: $mobile-width) and (orientation: landscape) {
  .modal {
    margin: 50px auto;
  }
  .page {
    padding: 10px;
    margin-bottom: 70px;
  }
}

.modal>form>.MuiGrid-root {
  margin: 30px auto;
  padding: 0px 10px 10px 10px;
}

.modal .MuiGrid-root>.MuiGrid-item {
  padding: 0 24px 0 0;
}

.modal form>fieldset {
  margin: 60px 0px;
  border-radius: 5px;
}

.modal form>fieldset:first-child {
  margin-top: 30px;
}

.modal form>fieldset>legend {
  text-transform: uppercase;
  font-weight: 600;
  position: relative;
  margin-top: -40px;
  float: left;
  white-space: nowrap;
  width: 200px;
}

.modal:focus {
  outline: 0;
}

.MuiPaper-root {
  padding: 8px 16px;
}

.loading-overlay {
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -128px 0 0 -128px;
  width: 256px;
  height: 256px;
}

.warning-text {
  color: $warning-dark;
}

.error-text {
  color: $error;
}

.success-text {
  color: $success;
}

.button-bar {
  margin: 30px 0 15px 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.modal .button-bar {
  justify-content: right;
}

.button-bar button {
  margin: 0 10px;
}

.make-unselectable {
  // make sure the text of the button isn't selectable/highlightable
  user-select: none; /* Standard syntax */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  -webkit-user-select: none; /* Safari */
}

/* Fix for missing text field border */
.MuiOutlinedInput-root {
  & .MuiOutlinedInput-notchedOutline {
    legend {
      max-width: 0px; /* Ensures the legend doesn't affect the border */
    }
  }
}
