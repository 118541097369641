.viewport-layout {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: hidden;
}

.viewport-layout-row {
  flex-flow: column;
  flex-wrap: wrap;
}

@media (--print) {
  .viewport-layout,
  .viewport-layout-row {
    display: block;
    width: 100%;
    overflow: visbile;
    height: auto;
    overflow-y: visible;
    overflow-y: visible;
    white-space: normal;
  }
}
