@import 'src/style/variable/variables.module';

.dropzone {
  cursor: pointer;
  padding: 20px;
  border-color: $grey-light;
  border-width: 2px;
  border-style: dashed;
  border-radius: 3px;
  outline: none;
  text-align: center;
  transition: border 150ms ease-in-out;
}

.dropzone > p {
  margin: 20px 0;
}

.dropzone-accept {
  border-color: $success;
}

.dropzone-active {
  border-color: $info;
}

.dropzone-reject {
  border-color: $error;
}
