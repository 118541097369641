@import 'src/style/variable/variables.module';

#permissions-screen {
  max-width: 1080px;
}

#permissions-screen .table-header {
  font-size: 1.4rem;
  border-bottom: 0px;
  line-height: 1.5;
  width: 10%;
  padding: 5px
}

#permissions-screen .table-cell {
  border-bottom: 0px;
  font-size: 1.3rem;
}

#permissions-screen .table-cell-group {
  border-bottom: 0px;
  font-size: 1.4rem;
  color: $primary;
  font-weight: bold;
  text-transform: uppercase;
  padding: 30px 5px 5px 5px;
  vertical-align: bottom;
}

#permissions-screen .table-cell-description {
  padding-left: 20px;
}
