@import 'src/style/variable/variables.module';

.task-scheduler {
  padding: 10px;
  width: 500px;
}

.MuiPickersDay-root {
  font-size: 9pt;
}
.MuiPickersCalendarHeader-label {
  font-size: 9pt;
}
.MuiTypography-root{
  font-size: 9pt;
}
.MuiPickersToolbarText-root{
  font-size: 25pt;
}
.MuiDateTimePickerToolbar-separator {
  line-height: unset;
}
.task-scheduler .Mui-selected{
  font-weight: bold;
}
