@import 'src/style/variable/variables.module';


.mobile-table .MuiTableCell-head {
  flex: 1 !important;
}
.mobile-table .MuiTable-root{
  display: flex;
  justify-content: center;
}
.mobile-table .MuiTableRow-root{
  display: flex;
  flex-direction: column;
}
.mobile-table .MuiTableBody-root{
  display: flex;
  flex-direction: row;
}
.mobile-table  .MuiTableHead-root{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.mobile-table .sub-headers,
.mobile-table .body-cell
{
  height: 62px !important;
}

.mobile-table .sub-headers {
  padding: 16px
}

.mobile-table .fixed-height {
  height: '62px' !important;
  min-height: '62px' !important;
  max-height: '62px' !important;
}

.mobile-table .main-headers{
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-table table tbody tr td {
  border-right: none !important;
}

.mobile-table tr,
.mobile-table td,
.mobile-table tbody {
  width: 100%;
}


.mobile-junction-table .body-cell {
  width: 100%;
}


.modal-no-padding-sides {
  padding-left: 0;
  padding-right: 0;
}

.default-junction-table .body-cell {
  min-width: 80px;
}
.default-control-panel-table  .body-cell {
  min-width: 100px;
}

.testing-report-modal {
  padding-bottom: 80px;
}

.testing-report-modal .main-headers {
  background-color: $primary-dark;
  color: white;
}

.testing-report-modal .mobile-table .main-headers {
  min-width: 30px;
}


.testing-report-modal .mobile-table .main-headers {
  padding: 0 !important;
}

.testing-report-modal .MuiTableCell-root {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.testing-report-modal .MuiTableCell-root:last-child {
  border-right: unset
}
.testing-report-modal{
  width: 95vw;
  margin-top: 30px;
  height: 95vh;
  position: relative;
  overflow: auto;
}
.testing-report-modal .main-header-cell {
  min-width: 90px !important;
  background-color: $primary-dark !important;
  color: white;
}
.testing-report-modal .MuiTableCell-head {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.testing-report-modal .MuiTableCell-head:last-child {
  border-right: unset;
}

.testing-reports-content .create-button {
  align-items: flex-end;
  display: flex;
}
.testing-reports-content .content-wrapper {
  position: relative;
}

.tab-label-size {
  font-size: medium;
  font-weight: bold;
}

.testing-report-modal .checkbox-rows.MuiTableRow-root:hover {
  background-color: lightgray;
}

.testing-report-modal .checkbox-rows-container .checkbox-rows {
  margin-bottom: 10px;
}

.MuiTableCol-root:hover {
  background-color: lightgray;
}

.stay-at-bottom{
  right: 20px
}
.no-border{
  border: unset;
}
.small-border{
  border: 1px solid black;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;

}
.button-bar.at-Junction{
  margin: 0px;
  margin-top: 5px;
}
.add-row:hover {
  background-color:  $primary-light !important;
}

.atDevice-alarm-check-sheet:not(.min-sub-headers) .atDevice-alarm-check-sheet .MuiTableCell-root:first-child {
  width: 180px;
}

.testing-reports-content tr.MuiTableRow-root:not(tr.MuiTableRow-head):hover {
  background-color: rgba(0, 0, 0, 0.12);
  cursor: pointer;
}
.testing-reports-content{
  margin-bottom: 24px;
}

.testing-report-modal .sub-headers.MuiTableCell-head {
  padding-top: 5px;
  padding-bottom: 5px;
}
.testing-report-modal .body-cell.MuiTableCell-root {
  padding-top: 5px;
  padding-bottom: 5px;
}
.testing-report-modal .next-button {
  z-index: 101;
}

.testing-report-modal .matrix-header-cell-remove-padding {
  padding-top:0;
  padding-bottom:0;
}

.testing-report-modal .matrix-header-row {
  padding-top:0;
  padding-bottom:0;
}

.testing-report-modal .main-header-row {
  position: sticky;
  top: 0;
  z-index: 2;
}

.testing-report-modal .flex-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}

.task-label {
  display: block;
  width: 100%;
}

.checkboxes {
  display: flex;
  flex-wrap: wrap;
}

.comment-field {
  width: 100%;
}

.testing-report-modal .checkboxes {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-right: 10px;
}

.testing-report-modal .comment-field {
  flex: 1 0 200px;
  max-width: 100%;
}

.atDevice-alarm-check-sheet .shrink-cell .MuiTableCell-root {
  max-width: 25px;
}

.remove-padding-for-junction-table {
  padding-left: 16px;
  padding-right: 0px;
  min-width: 32px;
}

.remove-padding-for-junction-table2 {
  padding-left: 8px;
  padding-right: 8px;
  min-width: 32px;
}

.testing-report-modal .rotated-text-junction2{
  writing-mode: vertical-rl;
  -moz-transform: scale(-1, -1);
  -webkit-transform: scale(-1, -1);
  -o-transform: scale(-1, -1);
  -ms-transform: scale(-1, -1);
  transform: scale(-1, -1);
  text-orientation: mixed;
  white-space: nowrap;
}

.testing-report-modal .mobile-table .rotated-text {
  writing-mode: vertical-rl;
  -moz-transform: scale(-1, -1);
  -webkit-transform: scale(-1, -1);
  -o-transform: scale(-1, -1);
  -ms-transform: scale(-1, -1);
  transform: scale(-1, -1);

  text-orientation: mixed;
  white-space: nowrap;
  position: absolute;
}

.testing-report-modal .centered-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.testing-report-modal .main-header-cell {
  width: 20px; /* Adjust the width as needed */
  max-width: 20px; /* Ensure it doesn't expand beyond this width */
  overflow: visible; /* Hide overflow text */
  word-wrap: break-word;
}

.testing-report-modal .matrix-header-row {
  position: sticky;
  top: 48px; /* Adjust based on the height of the first header row */
  z-index: 1;
}

.testing-report-modal .sticky-column {
  position: sticky;
  left: 0;
  background-color: #ffffff; /* Adjust background color to match your table */
  z-index: 1; /* Ensure it stays above other table cells */
}

.testing-report-modal .sticky-column-override-z {
  z-index: 105;
}

.testing-report-modal .comment-text {
  width: 100%;
  padding-right: 0 !important;
}

.alarms-check-matrix-container .formControl {
  max-width: 100%;
}

.finalize-container .MuiGrid-root .MuiGrid-item {
  padding-right: 0;
}

.testing-reports-content .create-report-button {
  margin-left: auto;
  margin-right: 0;
}
