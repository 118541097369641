.batch-schedule-list .MuiTableRow-root {
  cursor: pointer;
}


.tab-label-size {
  font-size: medium;
  font-weight: bold;
}

.batch-status-icon svg {
 height: 100px;
}
