@import 'src/style/variable/variables.module';

.temperature-unit-switch {
  display: flex;
}
.temperature-unit-switch h4 {
  font-weight: 500;
  padding-top: 10px;
  padding-right: 20px;
}

.temperature-unit-switch .MuiSwitch-switchBase {
  margin: 1px;
  padding: 1px;
  transform: translateX(-2px);
}

.temperature-unit-switch .MuiSwitch-switchBase.Mui-checked {
  color: $white;
  transform: translateX(24px);
}

.temperature-unit-switch .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb:before {
  content: "°C";
}

.temperature-unit-switch .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  opacity: 1;
  background-color: $primary-light;
}

.temperature-unit-switch .MuiSwitch-thumb {
  background-color: $primary;
  width: 32px;
  height: 32px;
  position: relative;
}

.temperature-unit-switch .MuiSwitch-thumb:before {
  content: "°F";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 6px;
  top: 4px;
}

.temperature-unit-switch .MuiSwitch-track {
  opacity: 1;
  background-color: $primary-light;
  border-radius: 10px;
}
