.comparison-selector {
  float: left;
  width: 32px;
  height: 32px;
}

.comparison-selector button {
  margin: 0;
  padding: 0;
}

.comparison-selector button > span > img > svg {
  color: green;
}

.comparison-selector button,
.comparison-selector button > span,
.comparison-selector button > span > img {
  width: 32px;
  height: 32px;
}

.comparison-selector-popup {
  position: relative;
  z-index: 1001;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12);
  padding: 10px;
  width: 116px;
  margin-left: -42px;
  margin-top: -6px;
  min-height: 80px;
}

.comparison-selector-popup button {
  float: left;
}

.comparison-selector-popup-overlay {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: auto;
}

.comparison-selector-popup div {
  display: block;
}
