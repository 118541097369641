.forced-on-modal {
  width: 90%;
  padding-left: 7.5%;
}

.forced-on-modal .button-bar button {
  margin-top: 20px;
  margin-right: 32px;
}

.forced-on-modal .MuiFormControl-root {
  margin: 5px 0px;
  width: 95%;
}

.circuit-information-subheading {
  font-size: 28px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.forced-on-modal {
  overflow-y: auto;
  min-height: 50%;
  max-height: 75%;
}
.deleted-info {
  color: red;
  font-weight: bold;
  width: 200px;
}
