#gauge-container .grid-gauge {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around
}
#gauge-container .gauge-container-top {
  display: flex;
}
#gauge-container .dummy-gauge {
  position: relative;
}
#gauge-container .gauge-overlay {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.01);
  backdrop-filter: blur(2px);
}
#gauge-container .gauge-large-loading {
  position: absolute;
  top: 40%;
  float: right;
  left: 40%;
  z-index: 2;
}
#gauge-container .gauge-container-title{
  margin-bottom: 15px;
}
#gauge-container p{
  margin-top: 0;
}
#gauge-container .device-status {
  display: flex;
  justify-content: center;
}
