.client-details .editable-cell-label p{
  margin: 0;
  font-size: 1.25rem;
}

.client-details .editable-cell-label {
  height: unset;
}
.client-details .labels {
  font-weight: bold;
  font-size: 1.25rem;
  width: 100px;
}
.client-details table {
  border-collapse: separate;
  border-spacing: 0 10px; /* Adds 10px spacing between rows */
}
