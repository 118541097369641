.textfield-label-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.textfield-label {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  color: rgba(0,0,0,0.6);
  margin-right: 5px;
}

.textfield-value {
  padding-left: 14px;
  margin: 0px;
}
