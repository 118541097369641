@import 'src/style/variable/variables.module';

#day-forecast{
  text-align: center;
  margin: 10px;
  width: 150px;
}
#day-forecast .temperature{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
#day-forecast h5{
  margin: 5px;
}
#day-forecast .max-temperature{
  color: $error
}
#day-forecast .min-temperature{
  color: $info
}
#day-forecast img{
  height: 48px;
  width: 48px;
}
#day-forecast img:hover{
  cursor: pointer;
}
