@import 'src/style/variable/variables.module';

.banner {
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  display: flex;
}
.banner-text {
  display: flex;
  width: fit-content;
  margin: auto;
  font-weight: bold;
}
.banner-warning {
  background-color: $warning;
}
.banner-info {
  background-color: $secondary;
}
.banner-error {
  background-color: $gauge-danger;
}

.banner .button-icon-only {
  padding: 0px;
  padding-left: 10px;
}
