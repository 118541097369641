#dashboard .MuiPaper-root {
  text-align: center;
}
#dashboard .innerElements {
  max-width: 1158px;
  margin: auto;
}

#dashboard h4,
#dashboard header {
  text-align: center;
  font-size: 12pt;
  min-height: 0;
  padding: 0;
}

#dashboard header > div {
  width: 100%;
}

#dashboard .MuiDivider-root {
  margin: 30px 0;
}

#dashboard table.horizontal-headings th {
  width: 70%;
}

#dashboard .rdt_TableCol {
  font-weight: 600;
}

#dashboard .rdt_TableHeadRow,
#dashboard .rdt_TableRow {
    min-height: 32px;
}

#dashboard .placeholder-graph img {
  opacity: 0.20;
}

#dashboard .placeholder-graph {
  position: relative
}

#dashboard .placeholder-graph-overlay {
  width: 100%;
  height: 100%;
  font-size: 2em;
}

#dashboard .placeholder-graph-overlay .inner {
  width: 80%;
}

#dashboard .freshness {
  font-size: 0.7em;
}

#dashboard .help-icon {
  top: 3px;
  position: relative;
}

#dashboard .rdt_TableHeader {
  min-height: inherit;
}

.MuiTooltip-tooltip ul {
  margin: 0;
  padding: 2px 0 8px 18px;
}

.MuiTooltip-tooltip div {
  font-weight: 600;
}

@media (max-width: 960px) {
  #dashboard .freshness {
    display: block;
  }
}

.outer {
  display: table;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.middle {
  display: table-cell;
  vertical-align: middle;
}

.inner {
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 960px) {
  .stat-spacer {
    width: 50px;
  }
}

#dashboard .carousel {
  margin: 40px 0 20px -2px;
  padding: 0 2px;
}

#dashboard .CarouselItem {
  min-height: 200px;
}

/* for IE 11 support */
.alarm-history-graph > svg {
  height: 400px !important;
}

.work-completed-graph > svg,
.alarms-by-status-graph > svg {
    height: 300px !important;
}

@media (max-width: 960px) {
  .alarm-history-graph > svg {
    height: 300px !important;
  }
}

@media (max-width: 520px) {
  .alarm-history-graph > svg {
    height: 200px !important;
  }

  .work-completed-graph > svg,
  .alarms-by-status-graph > svg {
      height: 200px !important;
  }
}
/* End IE 11 support */
