@import 'src/style/variable/variables.module';

.fileName{
  cursor: pointer;
  color: $info;
  overflow-wrap: break-word;
}
.file-upload-container .MuiTableHead-root .MuiTableCell-root {
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-upload-container  .MuiTableCell-root .MuiSelect-select  {
  white-space: normal;
  word-wrap: break-word;
}


.file-upload-container .editable-cell-label {
  display: flex;
  align-items: center;
  height: 100%;
  min-width: unset;
}
.file-upload-container .editable-cell-label p{
  display: flex;
}


.file-upload-container .editable-cell .MuiInputBase-root.MuiOutlinedInput-root {
  min-width: 100px;
  background: white;
  z-index: 1;
}
.file-upload-container .editable-cell.description-cell {
  min-width: 130px;
  background-color: rgba(255, 255, 255, 1);
}

.file-upload-container .editable-cell .check-button-save {
  background-color: white;
  margin-left: 5px;
}
