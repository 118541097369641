@import 'src/style/variable/variables.module';

.guide-wizard {
  margin-bottom: 100px;
}

.guide-wizard h2 {
  text-align: center;
}

.guide-wizard .editable-cell.revision-cell input {
  width: 70px;
}

.guide-wizard .editable-cell.description-cell input,
.guide-wizard .editable-cell.note-cell input {
    width: 170px;
}

.guide-wizard .rdt_TableHeader {
  min-height: inherit;
}

.guide-wizard .expandable {
  text-align: left;
  margin-top: 10px;
  padding-bottom: 0;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
}

.guide-wizard .expandable > span {
  text-align: left;
  vertical-align: text-bottom;
  padding-left: 10px;
}

.guide-wizard .expandable > div {
  display: flex;
}
.guide-wizard .expandable > div > span{
  padding-left: 20px;

}


.guide-wizard .expand-arrow {
  float: right;
  margin-top: -4px;
}

.guide-wizard .expandable .MuiSvgIcon-root {
  width: 32px;
  height: 32px;
  color: $black;
  margin-top: 3px;
}

.guide-wizard .MuiSvgIcon-root.complete,
.guide-wizard .device-status-loaded.complete {
  color: $success;
}

.guide-wizard .MuiOutlinedInput-root {
  padding: 2px;
  width: 280px;
}

.guide-wizard .MuiOutlinedInput-root .MuiIconButton-root {
  margin-top: 3px;
}

.guide-wizard .button-bar {
  margin: 20px 0px 0px 0px;
}

.guide-wizard .MuiSvgIcon-root.questionMarkIcon {
  border: 2px solid black;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  margin-left: 20px;
  margin-bottom: -2px;
}

.guide-wizard .inner-section {
  padding-bottom: 24px;
}

.guide-wizard .device-status-container {
  margin: 10px 30px;
  float: left;
}

.guide-wizard .device-status {
  width: 240px;
  float: left;
  display: flex;
}
.guide-wizard .complete-with-error{
  color: $warning;
}

.guide-wizard .error-status .MuiSvgIcon-root{
  color: $error;
}

.guide-wizard .device-status-tag {
  width: 250px;
  display: inline-block;
}

.guide-wizard .device-status-loaded {
  width: 30px;
  display: inline-block;
}

.guide-wizard .device-status-loaded .MuiSvgIcon-root {
  width: 18px;
  height: 18px;
}

.guide-wizard .commloop-container {
  margin-top: 20px;
}

.select-device-bar {
  margin: 30px;
  width: 100%;
  display: flex;
  justify-content: left;
}

.select-device-bar button {
  margin: 0 10px;
}

.select-device-bar .MuiOutlinedInput-root {
  height: 42px;
}

.squares-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.square {
  margin: 5px; /* Adjust the margin as needed */
}

.squares-paper {
  padding: 8px; /* Adjust the padding as needed */
  text-align: center;
  color: #000;
  border-radius: 10px;
  border: 1px solid #000;
  width: 150px; /* Ensure squares take up space only required by content */
  height: 100px; /* Ensure squares take up space only required by content */
  display: flex; /* Use flexbox */
  flex-direction: column; /* Stack content vertically */
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically */
}

.squares-paper:hover {
  color: $primary;
  border-color: $primary;
  cursor: pointer;
}
.selected-device {
  color: $primary;
  border-color: $primary;
}
