@import 'src/style/variable/variables.module';

.date-selector{
  margin: auto;
  max-width: 550px;
  display: flex;
  justify-content: space-around;
}

.custom-toolbar-option {
  display: flex;
  color: $primary;
  cursor: pointer;
  margin-right: 20px;
  line-height: 17px;
}
.custom-toolbar-one-line{
  display: flex;
  line-height: 17px;
}
.custom-toolbar-text {
  font-size: 15px;
}
.custom-toolbar-text-reg {
  font-weight: bold;
}
.custom-toolbar-download-icon {
  font-size: 17px !important;
  margin-right: 10px;
}

.custom-toolbar-text {
  display: flex;
  align-items: center;
}

.custom-toolbar-text .loading-spinner {
  margin-right: 5px;
}
