.switch-label-wrap {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.switch-label {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  color: rgba(0,0,0,0.6);
  margin-left: 0px;
  display: inline-flex;
  align-items: center;
}

.MuiFormControlLabel-root.switchControlMethod{
  margin-left: 0px;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
}

.switch-label-wrap .MuiFormControlLabel-root {
  align-items: flex-start;
}

.switch-label-wrap .MuiSwitch-root {
  left: -12px;
}

.switch-label {
  margin-top: 2.2px;
}
