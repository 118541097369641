@import 'src/style/variable/variables.module';

#LoginPage {
  height: 100%;
  flex-grow: 1;
}
.auth-form {
  max-width: 420px;
}
.auth-form .field {
  margin-bottom: 18px;
}

.legal {
  text-align: center;
  font-size: 9pt;
  color: $grey;
  margin-top: 50px;
}
.legal a {
  font-size: 9pt;
  color: $grey;
  margin-left: 10px;
  text-decoration: none;
}
.legal a:hover {
  color: $violet;
}

.instruction {
  color: $grey
}

.confirmation p{
  text-align: center;
}

.auth-form .reset-link {
  font-size: 9pt;
  text-decoration: none;
  margin-top: 2px;
  color: $grey;
  float: right;
}
.auth-form .reset-link:hover {
  color: $violet;
}

.auth-form .resend-link {
  font-size: 9pt;
  text-decoration: none;
  margin-top: 2px;
  color: $grey;
  float: right;
  margin-right: 30px;
}
.auth-form .resend-link:hover {
  color: $secondary;
}

.auth-form-buttons {
  margin-top: 30px;
}
.auth-form-buttons-code {
  margin: auto;
  margin-top: 30px;
}

.azure-link {
  float:right;
}

.auth-form-buttons a {
  padding: 8px 4px;
}

@media (--mobile) {
  .left {
      display: none;
  }
  .right {
      width: 100%;
  }
}
.mfa-label-login {
  text-align: center;
}
.mfa-option-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}
.mfa-button {
  margin-left: 5px;
  margin-right: 5px;

}
.mfa-button  button {
  transition: background-color 100ms, border-color 100ms, color 100ms !important;
}
.mfa-button .button-border:enabled:hover span {
  color: $secondary !important;

}
.mfa-button .button-border:hover  {
  color: $secondary !important;
  border-color: $secondary !important;
}
.mfa-button-azure {
  margin-left: 5px;
  margin-right: 5px;
  color: $primary;
  border: 1px solid;
  border-color: currentColor;
  padding: 7px;
  border-radius: 3px;
  transition: background-color 100ms, border-color 100ms, color 100ms;
}

.other-auth-options .auth-option {
  border: 1px solid $grey-light;
  border-radius: 5px;
  display: flex;
  justify-content: center; /* Horizontal alignment */
  align-items: center;     /* Vertical alignment */
  cursor: pointer;
  padding: 5px 0px;
  margin-bottom: 20px;
}
.other-auth-options .auth-option p {
  font-weight: bold;
  margin: 0px;
  padding-left: 10px
}
