@import 'src/style/variable/variables.module';
.copyable {
  transition: border 100ms;
  border-radius: 5px;
  padding: 5px 40px 3px 8px;
  border: 1px solid transparent;
  position: relative;
  min-height: 35px;
  min-width: 30px;
  margin-bottom: 6px;
}

.copyable-icon {
  position: absolute;
  top: 4px;
  right: 4px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 100ms;
}

.copyable-icon-container {
  position: relative;
  width: 18px;
  height: 18px;
  transition: transform 500ms;
  transform-style: preserve-3d;
}

.copyable-icon-back {
  transform: rotateY(180deg);
}

.copyable-icon-success .copyable-icon-container {
  transform: rotateY(180deg);
}

.copyable-icon-back, .copyable-icon-front {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
}

.copyable:hover {
  border: 1px solid $grey-light;
}

.copyable:hover > .copyable-icon {
  opacity: 1;
}
