.error-screen {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.error-screen-text {
  margin: 0 0 0 60px;
  font-size: 2.1rem;
}

.error-screen-text h1 {
  font-size: 3.6rem;
}

.error-screen__image {
  width: 320px;
}

@media (max-width: 800px) {
  .error-screen__image {
    width: 220px;
  }

  .error-screen-text > img {
    width: 260px;
  }

  .error-screen-text {
    font-size: 1.6rem;
  }

  .error-screen-text h1 {
    font-size: 2.8rem;
  }
}

@media (max-width: 620px) {
  .error-screen__image {
    display: none;
  }

  .error-screen-text {
    margin: 0 10px;
    font-size: 1.6rem;
  }
}
