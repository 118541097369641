@import 'src/style/variable/variables.module';
.note-modal {
  width: 90%;
  padding-left: 7.5%;
}

.note-modal .button-bar button {
  margin-top: 20px;
  margin-right: 25px;
}

.note-modal .MuiFormControl-root {
  margin: 5px 0px;
  width: 95%;
}

.circuit-information-subheading {
  font-size: 28px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.note-modal {
  overflow-y: auto;
  min-height: 50%;
  max-height: 75%;
}

@media (max-width: $mobile-width) {
  #notes-model .MuiGrid-root>.MuiGrid-item {
    padding: 0px !important;
  }
  .note-modal .MuiFormControl-root {
    width: 100%;
  }
  #notes-model .MuiGrid-root {
    margin-top: 0px;
  }
}
@media (max-width: $mobile-width) and (orientation: landscape) {
  .note-modal {
    padding-left: 5%;
  }
}
