@import 'src/style/variable/variables.module';
.toast-content {
  position: relative;
}

body .Toastify__toast {
  border-radius: 8px;
}

.toast.success {
  color: $primary-surface;
  background-color: $success;
  border-color: $success;
}

.toast.error {
  color: $primary-surface;
  background-color: $error;
  border-color: $error;
}

.toast.warn {
  color: $primary-solid;
  background-color: $warning;
  border-color: $warning;
}

.toast.info {
  color: $primary-surface;
  background-color: $primary;
  border-color: $primary;
}

.toast a {
  font-weight: 700;
  cursor: pointer;
}

.toast a:hover {
  text-decoration: underline;
}

.toast .toast-content {
  display: flex;
  align-items: center;
}

.toast .toast-content .toast-icon {
  font-size: 48px;
}

.toast.warn .Toastify__close-button {
  color: $primary-solid
}

.toast .toast-content .toast-message {
  margin-left: 1rem;
}

.Toastify .Toastify__toast-container--bottom-left {
  left: 64px;
  z-index: 99;
}
.Toastify .Toastify__toast-container--top-right {
  width: fit-content;
  max-width: 500px;
}
.Toastify .Toastify__close-button--light{
  color: white;
  opacity: 1;
}

@media (max-width: $mobile-width) {
  .Toastify .Toastify__toast-container--top-right {
    left: inherit !important;
    margin-top: 10px;
  }
  .Toastify__toast {
    margin-bottom: 1rem !important;
  }
}
