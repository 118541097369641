.customizable-statistics h4,
.customizable-statistics header {
  text-align: center;
  font-size: 12pt;
  min-height: 0;
  padding: 0;
}

.minor-stat {
  margin: 0 -16px;
}

.stat-spacer {
  display: inline-block;
}
