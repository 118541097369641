#device-screen .device-statistics {
  text-align: center;
}

#device-screen .stat-box {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

#device-screen .title .button{
  padding: 0;
}
