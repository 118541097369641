@import 'src/style/variable/variables.module';

.loading-placeholder p {
  color: $primary-solid;
  font-size: 24px;
  margin-top: -4px;
  text-transform: uppercase;
  letter-spacing: 7px;
  margin-left: 4px;
}
