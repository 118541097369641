@import 'src/style/variable/variables.module';

.change-facility-text > span{
  font-size: 14px;
  color: $primary-dark;
  opacity: 1;
}
.MuiList-root.change-facility{
  padding:0px;
}
.MuiList-root.change-facility >.MuiListItemButton-root:hover{
  background-color: rgba(0, 0, 0, 0.04);
}


.change-facility-text.active > span{
  font-weight: bold;
}

.navbar-item-flyout.MuiSvgIcon-root{
  fill: $primary-dark
}
.change-facility .MuiListItemText-primary {
  width: fit-content;
}
