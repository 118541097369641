@import 'src/style/variable/variables.module';
.viewport-layout-pane-top {
  width: 100%;
}

.viewport-layout-pane-middle {
  height: 100%;
  width: 100%;
  flex: 1;
  min-height: 0;
  min-width: 0;
  overflow: auto;
  padding-bottom: 50px;
}

.viewport-layout-pane-standard-background {
  background-color: $primary-surface;
}

.viewport-layout-pane-right {
  display: flex;
  flex: 1 1 auto;
  min-width: 0px;
}

.viewport-layout-pane-left {
  height: 100%;
}

.viewport-layout-main-pane-left {
  z-index: 200;
}

@media (max-width: $mobile-width) {
  .viewport-layout-pane-left {
    height: 220px;
    width: 0;
  }
}
@media (--print) {
  .viewport-layout-pane,
  .viewport-layout-pane-top,
  .viewport-layout-pane-standard-background,
  .viewport-layout-pane,
  .viewport-layout-pane-middle,
  .viewport-layout-pane,
  .viewport-layout-pane-right,
  .viewport-layout-pane,
  .viewport-layout-pane-bottom {
    display: block;
    width: 100%;
    height: auto;
    overflow: auto;
    white-space: normal;
  }
}
