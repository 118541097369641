@import 'src/style/variable/variables.module';

.batch-status .MuiSvgIcon-root {
  font-size: 30px;
  margin-bottom: -7px;
  margin-left: 10px;
}

.back-button {
  position: absolute;
  top: 10px;
  background-color: $primary;
  color: white;
}
