.progress-container {
  top: 70px;
  right: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  min-width: 300px;
  height: 150px;
  z-index: 9999;
  background-color: #fff;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.5);
}
