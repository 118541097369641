#recipe-screen .recipeTitle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#recipe-screen .recipe-bar {
  margin-bottom: 10px;
}

#recipe-screen  .recipeDetails {
  border: dashed 1px #c7c7c7;
  padding: 10px;
  margin-top: -0px
}
#recipe-screen  .recipeDetails .inner{
  margin-top: -40px
}
#recipe-screen .recipeDetails .title1{
  background: white;
  width: fit-content;
}

#recipe-screen .recipeDetails .addConditionButton{
  margin: 0px 85px 0px auto;
  width: 200px;
}

#recipe-screen .recipeDetails .addConditionButton{
  margin: 0px 85px 0px auto;
  width: 200px;
}

#recipe-screen .recipeCondition {
  margin: 20px 30px;
  display: flex;
}

#recipe-screen .select-recipe-setting {
  width: 380px;
}

#recipe-screen .recipeCondition .comparison{
  margin: 0px 100px;
}

#recipe-screen .recipeCondition .input{
  width: 350px;
  margin-top: -7px;
  float: right;
  margin-left: auto;
  margin-right: auto;
}

#recipe-screen .recipeCondition .buttonRow{
  margin-left: auto;
  display: flex;
  margin-top: -10px;
}
#recipe-screen .recipeCondition .buttonRow .deleteIcon{
  font-size: 24;
  margin-right: 25px
}
