@import 'src/style/variable/variables.module';

.button {
  background: none;
  outline: none;
  border: none;
  padding: 10px;
  font-size: 1.6rem;
  margin: 0;
  cursor: pointer;
  display: inline;
  user-select: none;
  color: $primary-solid;
  border-radius: 3px;
  transition: background-color 100ms, border-color 100ms, color 100ms;
}

.button-large {
  font-size: 2.4rem;
}

.button-medium {
  font-size: 2.0rem;
}

.button-wide {
  padding-left: 40px;
  padding-right: 40px;
}

.button span {
  white-space: nowrap;
  vertical-align: middle;
  display: inline-block;
  margin-top: 2px;
}
.button-background {
  background: $input-background;
}

/* Position & Structure of Variations */
.button-compact {
  padding: 4px 6px;
  font-size: 1.3rem;
}

.button .button-icon {
  margin-right: 6px;
  margin-top: 0;
}
.button .button-icon.button-icon-icon-only {
  margin-right: 0;
}
.button-icon,
.button-icon svg {
  width: 18px;
  height: 18px;
}

.button-medium .button-icon,
.button-medium .button-icon svg {
  width: 32px;
  height: 32px;
  stroke-width: 0.75;
}

.button-medium:hover .button-icon,
.button-medium:hover .button-icon svg {
  fill: $primary;
  color: $white;
}

.button-large .button-icon,
.button-large .button-icon svg {
  width: 48px;
  height: 48px;
  stroke-width: 0.75;
}

.button-large:hover .button-icon,
.button-large:hover .button-icon svg {
  fill: $primary;
  color: $white;
}

/* Base Color and Border Settings */

.button-border {
  color: $primary;
  border: 1px solid;
  border-color: currentColor;
}

.button-cta {
  color: $primary;
  background-color: currentColor;
}
.button-cta span {
  color: $white;
}

/* Disabled Status */

.button:disabled{
  opacity: 0.5;
}

/* Hover and Active Color and Border Settings */

.button:enabled:hover {
  box-shadow: inset 0 0 0 99999px rgba(0, 0, 0, 0.2);
}
.button:enabled:active{
  transform: translateY(1px);
}

.button-border:enabled:active,
.button-background:enabled:active,
.button-cta:enabled:active{
  filter: saturate(150%);
  background-color: currentColor;
  box-shadow: inset 0 0 0 99999px rgba(0, 0, 0, 0.2);
}

.button-border:enabled:hover span,
.button-cta:enabled:hover span,
.button-border:enabled:active span,
.button-cta:enabled:active span {
  color: $white;
}

.button-border:enabled:hover {
  background-color: currentColor;
}

.button-cta:enabled:hover {
  filter: saturate(150%);
  box-shadow:inset 0 0 0 99999px rgba(0, 0, 0, 0.2);
}
