@import 'src/style/variable/variables.module';
.circular-loading .MuiTypography-root {
  font-size: 15px;
  margin-bottom: 9px;
}

.circular-loading .MuiSvgIcon-root.complete {
  color: $success;
  font-size: 50px;
}

.circular-loading .MuiSvgIcon-root.complete-with-error {
  color: $warning;
  font-size: 50px;
}

.circular-loading {
  display: inline-block;
}
